import React, { useState, useEffect } from 'react'
import { useWindowDimensions  } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import { Typography, Box, Grid, Paper } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import UserInfo from '../../components/UserInfo'
import SongList from '../../components/SongList'
import SongCardPublic from '../../components/SongCardPublic'
import Paginator from '../../components/Paginator'
import Cookies from 'js-cookie';
import { LIMIT_25 } from '../../config'
import { fetchSongsByUserId, fetchLikesByUserIdAndItemIds } from '../../redux/slices/songs'
import Player from '../../components/Player'


export default function Cabinet() {
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.data)
  const userId = user?._id
  const isAuth = useSelector(selectIsAuth)

  const { width } = useWindowDimensions();
  const isTablet = width > 768

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }

  useEffect(() => {
    const value = Cookies.get('isRegister')
    if (!value) { Cookies.set('isRegister', true, { expires: 3650 }) }
  }, [])

  const [page, setPage] = useState(1)

  useEffect(()=> {
    if (userId) {
        dispatch(fetchSongsByUserId({ userId, status: 'published', page, limit: LIMIT_25 }))
    }
  }, [userId, dispatch, page])

  const songs = useSelector(state => state.songs.songs)
  const songListIds = songs.items.map(item => item._id)

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchLikesByUserIdAndItemIds({ sender: userId, itemsArray: songListIds }))
    }
  }, [dispatch, JSON.stringify(songs)])
  const likesArray = useSelector(state => state.songs.likes)

  if (!user) return (<LoadingButton />)

  return (
    <Box>
        <Player />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2 }}>
                  <UserInfo user={user} />
              </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
              <Grid>
                <Paper sx={{ p: 2 }}>

                  <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
                    Песни
                  </Typography>
                  { !songs.items.length && <Typography variant='body1'>На данный момент опубликованных песен нет</Typography>}

                  <Grid container spacing={3} sx={{ mt: 2 }}>
                  {
                      songs.items.map((item, index) => (
                        <Grid item xs={12} sm={12} md={12} key={index}>
                            {
                              isTablet ? (
                                <SongList
                                  item={ item }
                                  likesArray={ likesArray }
                                  initialSongList='songs'
                                  columns={2}
                                />
                              ) : (
                                <SongCardPublic
                                  item={ item }
                                  likesArray={ likesArray }
                                  initialSongList='songs'
                                />
                              )
                            }
                        </Grid>
                    ))}
                  </Grid>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                      <Paginator
                          page={page}
                          setPage={setPage}
                          totalPages={songs.totalPages}
                      />
                  </Box>


                </Paper>
              </Grid>
          </Grid>
        </Grid>
      </Box>
  )
}
