import React from 'react'
import { Stack, Link, Avatar, Typography } from '@mui/material';
import { BASE_URL } from '../../config';
import { stringToColor } from '../../clientHelpers/stringToColor'
import s from './index.module.css'
import moment from 'moment';

export default function UserCard({ user }) {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 60,
        height: 60
      },
      children: `${name[0]}`,
    };
  }

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Link href={`/user/${user.login}`} sx={{ textDecoration: 'none' }}>
        <Avatar
          {...stringAvatar(`${user.name || user.login}`)}
          src={`${BASE_URL}${user.avatar}`}
        />
      </Link>

      <Stack sx={{ ml: 2 }}>
        <Link
            href={`/user/${user.login}`}
            variant='body1'
            sx={{ fontWeight: 600 }}
          >
              {user.name || user.login}
        </Link>
        <Typography variant='caption' color='gray' sx={{ mt: 0.5 }}>
          Опубликовано песен: { user.countSongs ?? 'нет данных' }
        </Typography>
        <Typography variant='caption' color='gray' sx={{ mt: 0.5 }}>Последний визит: { moment(user.lastVisit).format('DD.MM.YYYY HH:mm') }</Typography>
      </Stack>
    </Stack>
  )
}
