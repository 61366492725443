import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import s from '../index.module.css'
export default function SongCardSkeleton() {
  return (
    <Stack className={s.card}>
      <Skeleton animation="wave" variant="rounded" height={60} />
      <Stack sx={{ padding: '8px' }} direction='row' justifyContent='space-between' >
        <Stack direction={'column'}>
          <Skeleton animation="wave" variant="text" sx={{ width: '200px' }} />
          <Skeleton animation="wave" variant="text" sx={{ width: '200px' }} />
          <Skeleton animation="wave" variant="text" sx={{ width: '200px' }} />
        </Stack>
        <Skeleton variant="circular" width={40} height={40} sx={{ mt: 2}} />
      </Stack>
      <Stack direction='row' sx={{ padding: '8px', mt: 1, justifyContent: 'space-between',  background: '#eee' }}>
          <Stack direction='row'>
              <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
              <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
              <Skeleton variant="circular" width={20} height={20} />
          </Stack>
          <Stack direction='row'>
              <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
              <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
              <Skeleton variant="circular" width={20} height={20} />
          </Stack>
          <Stack direction='row'>
              <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
              <Skeleton variant="circular" width={20} height={20} />
          </Stack>
      </Stack>
      </Stack>
  );
}
