import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import TopMenu from './TopMenu'
import { Container, Box } from '@mui/material'
import s from './index.module.css'
import Footer from './Footer'

export default function Layout () {
    return (
        <Box sx={{backgroundColor: '#D6D6D6', minHeight: '100vh'}}>
            <TopMenu />
            <Header />
            <Container className={s.container}>
                <Outlet />
            </Container>
            <Footer />
        </Box>
    )
}
