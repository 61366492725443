import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper, Box, Stack, Tooltip, IconButton } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchSongsByUserId } from '../../redux/slices/songs'
import { styled } from '@mui/material/styles';
import { LIMIT_10, GENERAL, FAVORITE, ARCHIVE } from '../../config'
import Paginator from '../../components/Paginator'
import AdminMenu from '../../components/AdminMenu'
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate, useParams } from 'react-router-dom'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SongCardAdmin from '../../components/SongCardAdmin'
import Player from '../../components/Player'
import SongCardSkeleton from '../../components/SongCardAdmin/Skeleton'

const filters = [
  {
      title: 'Все, кроме тех, что не понравились',
      filter: GENERAL,
      icon: FormatAlignJustifyIcon
  },
  {
      title: 'Понравились',
      filter: FAVORITE,
      icon: ThumbUpIcon
  },
  {
      title: 'Не понравились',
      filter: ARCHIVE,
      icon: ThumbDownIcon
  },
  {
      title: 'Опубликованные песни',
      filter: 'published',
      icon: RadioButtonCheckedIcon
  }
]

export default function AUser() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState(GENERAL)
  const { userId } = useParams()

  const isAuth = useSelector(selectIsAuth)
  const userAdmin = useSelector(state => state.auth.data)
  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }
  if (userAdmin && !userAdmin.isAdmin) {
    return <Navigate to='/' />
  }

  useEffect(()=> {
    if (userId) {
        dispatch(fetchSongsByUserId({ userId, status: filter, page, limit: LIMIT_10 }))
    }
  }, [userId, filter, dispatch, page])
  const songs = useSelector(state => state.songs.songs)

  if (!songs) return (<LoadingButton />)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  return (
    <>
      <Player />
      <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Item>
              <AdminMenu />
            </Item>
          </Grid>
          <Grid item xs={12} md={9}>
              <Item>
                  <Typography variant='h3'>Студия: { songs.items[0]?.user?.name }</Typography>

                  <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end', mb: 1 }}>
                      <Typography variant="body2" sx={{ mr: 1 }}>Показать: </Typography>
                      {
                          filters.map((item, index) => (
                              <Tooltip title={item.title} placement='top' key={index}>
                                  <IconButton onClick={()=> { setFilter(item.filter) }}>
                                      <item.icon sx={{ width: 18, height: 18 }} color={ filter === item.filter ? 'primary' : undefined } />
                                  </IconButton>
                              </Tooltip>
                          ))
                      }
                  </Stack>

                  {
                      songs.items.map((item, index) => (
                          item.song.audio_url ? (
                              <SongCardAdmin
                                  key={index}
                                  item={item}
                                  filter={filter}
                              />
                          ) : (
                              <SongCardSkeleton key={index} />
                          )
                      ))
                  }

                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={songs.totalPages}
                    />
                  </Box>
              </Item>
          </Grid>
      </Grid>
    </>
  )
}
