import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, TextField, Button, Stack, Autocomplete } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuth } from '../../redux/slices/auth';
import { styled } from '@mui/material/styles';
import AdminMenu from '../../components/AdminMenu';
import { Navigate, useNavigate  } from 'react-router-dom';
import { SUNO_API_URL, BASE_URL, GENERAL, LIMIT_5 } from '../../config';
import axios from 'axios';
import localAxios from '../../axios'
import s from './index.module.css'
import { fetchAllUsersAdmin, fetchUpdateUserAdmin } from '../../redux/slices/users'
import _ from 'lodash'

const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

export default function AVoice() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const isAuth = useSelector(selectIsAuth);
  const userAdmin = useSelector(state => state.auth.data);

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />;
  }
  if (userAdmin && !userAdmin.isAdmin) {
    return <Navigate to='/' />;
  }

  const [voiceId, setVoiceId] = useState('');
  const [song, setSong] = useState('');
  const [name, setName] = useState('');
  const [selectedUser, setSelectedUser] = useState({})
  let query = { name: name }

  useEffect(()=> {
    if (name.length) {
      setTimeout(() => {
        dispatch(fetchAllUsersAdmin({ limit: LIMIT_5, query }))
      }, 250)
    } else {
      dispatch(fetchAllUsersAdmin({ limit: LIMIT_5 }))
    }
  }, [dispatch, JSON.stringify(query)])

  const users = useSelector(state => state.users.usersAdmin)

  const usersArray = users.items.map(user => ({
    label: user.name,
    userId: user._id
  }))

  async function getData(e) {
    e.preventDefault();

    try {
      const { data } = await axios.get(`${SUNO_API_URL}/api/get?ids=${voiceId}`)
      setSong(data[0])
    } catch (error) {
      console.error('Error fetching voice data:', error);
    }
  }

  async function saveSongs () {
    const voiceValues = {
        user: selectedUser.userId,
        status: GENERAL,
        song
    }

    try {
      await localAxios.post(`${BASE_URL}/song/admin/add-voice`, voiceValues).then(()=> {
        dispatch(fetchUpdateUserAdmin({
          userId: selectedUser.userId,
          voiceId: voiceId
        }))
        navigate(`/admin/user/${selectedUser.userId}`);
      })
    } catch (e) {
        console.log('e', e)
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Item>
            <AdminMenu />
          </Item>
        </Grid>
        <Grid item xs={12} md={9}>
          <Item>
            <Typography variant='h3'>Данные о голосе</Typography>
            <TextField
              sx={{ mt: 4 }}
              margin="normal"
              required
              fullWidth
              id="id"
              name="Введите ID голоса с сайта SUNO"
              label="Введите ID голоса с сайта SUNO"
              value={voiceId}
              onChange={(e) => setVoiceId(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, width: 200 }}
              onClick={getData}
            >
              Получить данные
            </Button>

            {
              song && (
                <>
                <Typography variant='h4' sx={{ mt: 4 }}>Полученные данные</Typography>
                <Stack direction={'row'} alignItems={'center'}  sx={{ mt: 2 }}>
                    <img className={s.image} src={`${song.image_url}`} />
                    <Typography variant='body1' sx={{ fontWeight: 600, ml: 2 }}>{ song.title }</Typography>
                </Stack>

                <Typography variant='h4' sx={{ mt: 4 }}>Чей это голос?</Typography>
                <Typography variant='caption'>Голос будет сохранен в аккаунте выбранного пользователя</Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={usersArray}
                  fullWidth
                  sx={{ mt: 2 }}
                  onChange={(event, newValue) => {
                    setSelectedUser(newValue);
                  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Начните набирать имя пользователя"
                      onChange={(e)=> setName(e.target.value)}
                    />
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, width: 200 }}
                  onClick={saveSongs}
                >
                  Сохранить данные
                </Button>         
                </>
              )
            }
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
