import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper, Box, Stack, Switch, Avatar } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchAllSongsListAdmin } from '../../redux/slices/songs'
import { styled } from '@mui/material/styles';
import { LIMIT_25 } from '../../config'
import Paginator from '../../components/Paginator'
import AdminMenu from '../../components/AdminMenu'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import Player from '../../components/Player'
import PlayButton from '../../components/PlayButton';
import { Navigate } from 'react-router-dom'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

export default function ASongs() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [songsByDate, setSongsByDate] = useState([])

  const isAuth = useSelector(selectIsAuth)
  const userAdmin = useSelector(state => state.auth.data)
  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }
  if (userAdmin && !userAdmin.isAdmin) {
    return <Navigate to='/' />
  }

  useEffect(()=> {
    dispatch(fetchAllSongsListAdmin({ page, limit: LIMIT_25 }))
  }, [dispatch, page])
  const songs = useSelector(state => state.songs.songsAdmin)

  useEffect(()=> {
    if (songs && songs.items.length) {
      const updatedSongs = transformSongsWithDateHeaders(songs)
      setSongsByDate(updatedSongs)
    }
  }, [songs, JSON.stringify(songs)])

  const transformSongsWithDateHeaders = (songs) => {
    return songs.items.reduce((acc, song) => {
      const songDate = new Date(song.createdAt).toLocaleDateString();

      if (!acc.length || acc[acc.length - 1].songDate !== songDate) {
        acc.push({ type: 'date', songDate });
      }

      acc.push({ ...song, type: 'song', songDate });
      return acc;
    }, []);
  };

  if (!songsByDate.length) return (<LoadingButton />)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  return (
    <>
      <Player />
      <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Item>
              <AdminMenu />
            </Item>
          </Grid>
          <Grid item xs={12} md={9}>
              <Item>
                  <Typography variant='h3'>Песни</Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">

                      <TableHead>
                        <TableRow>
                          <TableCell>Песня</TableCell>
                          <TableCell>Дата</TableCell>
                          <TableCell align='center'>Опубликовано</TableCell>
                          <TableCell align='center'>Успешно сгенерировано</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>

                        { songsByDate.map((row, index) => (
                          row.type === 'date' ? (
                            <TableRow key={`date-${index}`}>
                              <TableCell colSpan={4} sx={{ background: '#dbecfc' }}>
                                <Typography variant="h6" color='primary'>{row.songDate}</Typography>
                              </TableCell>
                            </TableRow>
                          ) :(
                            <TableRow
                              key={row._id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell>
                                  <Stack direction={'row'}>
                                    <PlayButton song={row.song} />
                                    <Stack sx={{ ml: 2 }}>
                                      <Typography variant='body2' sx={{ fontWeight: 'bold' }}>{row.song.title}</Typography>
                                      <Typography variant='caption' sx={{ mt: 0.5 }}>{row.user.name}</Typography>
                                    </Stack>
                                  </Stack>
                              </TableCell>
                              <TableCell>
                                <Typography variant='body2'>{ moment(row.createdAt).format('DD.MM.YYYY HH:mm') }</Typography>
                              </TableCell>
                              <TableCell align='center'>
                                  {
                                    row.published && (
                                      <CheckCircleIcon color="success" />
                                    )
                                  }
                              </TableCell>
                              <TableCell align='center' sx={{ width: "140px" }}>
                                  {
                                    row.song.status === 'complete' ? (
                                      <CheckCircleIcon color="success" />
                                    ) : (
                                      <DoDisturbOnIcon color="error" />
                                    )
                                  }
                              </TableCell>
                            </TableRow>
                          )
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={songs.totalPages}
                    />
                  </Box>
              </Item>
          </Grid>
      </Grid>
    </>
  )
}
