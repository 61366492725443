import React, { useState } from 'react';
import moment from 'moment';
import { Link, Typography, Stack, Avatar, Box, Button, IconButton } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendComment from '../SendComment';
import { addLineBreaks } from '../../clientHelpers/addLineBreaks';
import { stringToColor } from '../../clientHelpers/stringToColor';
import { BASE_URL, CABINET, ADMIN } from '../../config';
import { fetchRemoveComment } from '../../redux/slices/comments'
import Modal from '../Modal'
import { useDispatch } from 'react-redux'

export default function Comment({ item, app }) {
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(false);
    const [showRemoveCommentModal, setShowRemoveCommentModal] = useState(false)

    function removeComment (removeCommentId) {
        dispatch(fetchRemoveComment(removeCommentId));
        setShowRemoveCommentModal(false)
      }

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const commentStyle = item.level === 1 ? { mt: 4 } : { ml: 6, mb: 2 }

    const renderReplies = (replies) => {
        return (
            replies.map((reply) => (
                <Comment
                    key={reply._id}
                    item={reply}
                    app={app}
                    removeComment={removeComment}
                />
            ))
        )
    };

    return (
        <>
        <Modal
            open={showRemoveCommentModal}
            title='Вы уверены, что хотите удалить комментрий?'
            onClose={()=> setShowRemoveCommentModal(false)}
            onCancel={()=> setShowRemoveCommentModal(false)}
            onSuccess={()=> removeComment(item._id)}
        />
        <Box sx={commentStyle}>
            <Box
                sx={{
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    p: 2, position: 'relative',
                    backgroundColor: '#f9f9f9',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: '14px',
                        bottom: '-6px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: '#f9f9f9',
                        transform: 'rotate(45deg)',
                        border: '1px solid #ccc',
                        borderTop: 'none',
                        borderLeft: 'none',
                        zIndex: 1
                    }
                }}
            >
                <Typography variant='body2'>{addLineBreaks(item.text)}</Typography>
            </Box>
            <Stack direction='row' justifyContent='space-between' sx={{ mt: 1.5 }}>
                <Stack direction='row' alignItems='center'>
                    <Avatar
                        {...stringAvatar(item.sender.name)}
                        src={`${BASE_URL}${item.sender.avatar}`}
                    />
                    <Stack direction='column' sx={{ ml: 1 }}>
                        {
                            (app === CABINET || app === ADMIN) && (
                                <Typography variant='body2'>Комментарий к песне {' '}
                                    <Link href={`/song/${item?.item?._id}`}>
                                        {item.item?.song.title}
                                    </Link>
                                </Typography>
                            )
                        }

                        <Link variant='body2' href={`/user/${item.sender.login}`}>
                            {item.sender.name}
                        </Link>
                        <Typography variant='caption' color='gray'>
                            {moment(item.createdAt).format('DD.MM.YYYY, HH:mm')}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems={'flex-start'}>
                    {
                        app !== ADMIN && (
                            <Button
                                sx={{ height: '36px' }}
                                startIcon={<ReplyIcon />}
                                size='small'
                                variant="outlined"
                                onClick={handleToggle}
                            >
                                Ответить
                            </Button>
                        )
                    }
                    {
                        (app === CABINET || app === ADMIN) && (
                            <IconButton
                                sx={{ ml: 1 }}
                                onClick={() => setShowRemoveCommentModal(true)}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        )
                    }
                </Stack>
            </Stack>
            {expanded && (
                <Box sx={{ mt: 2 }}>
                    <SendComment
                        user={item.receiver._id}
                        level={2}
                        commentId={item._id}
                        rootCommentId={item.rootCommentId || item._id}
                        item={item.item._id}
                        app={CABINET}
                    />
                </Box>
            )}
            {item.replies && item.replies.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    {renderReplies(item.replies)}
                </Box>
            )}
        </Box>
        </>
    );

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${name[0]}`,
        };
    }
}
