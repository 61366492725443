import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import { LIMIT_10 } from "../../config";

export const fetchAddComment = createAsyncThunk(
    'comments/fetchAddComment',
    async function (values, { rejectWithValue }) {
        try {
            const res = await axios.post(`/comment/add-comment`, values)

            if (res.status !== 200) {
                throw new Error('Server error: add comment')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchCommentsByItemId = createAsyncThunk(
    'comments/fetchCommentsByItemId',
    async function (itemId, { rejectWithValue }) {
        try {
            const res = await axios.get(`/comment/get-comments-by-itemid/${itemId}`)

            if (res.status !== 200) {
                throw new Error('Server error: fetch comments')
            }

            return res.data.itemComments
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchCommentsByUserId = createAsyncThunk(
    'comments/fetchCommentsByUserId',
    async function ({userId, page = 1, limit = LIMIT_10}, { rejectWithValue }) {
        try {
            const res = await axios.get(`/comment/get-comments-by-userid/${userId}/${page}/${limit}`)

            if (res.status !== 200) {
                throw new Error('Server error: fetch comments by user id')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchRemoveComment = createAsyncThunk(
    'comments/fetchRemoveComment',
    async function (commentId, { rejectWithValue }) {
        try {
            await axios.delete(`/comment/remove-comment/${commentId}`);
            return commentId
        } catch (error) {
            console.error('Error deleting comment:', error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchAllCommentsAdmin = createAsyncThunk(
    'comments/fetchAllCommentsAdmin',
    async function ({ page = 1, limit = LIMIT_10 }, { rejectWithValue }) {
        try {
            const res = await axios.get(`/comment/get-all-comments-admin/${page}/${limit}`)

            if (res.status !== 200) {
                throw new Error('Server error: fetch all comments - admin')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const initialState = {
    itemComments: {
        items: [],
        totalPages: 0,
        status: 'loading',
        error: null
    },
    commentsAdmin: {
        items: [],
        totalPages: 0,
        status: 'loading',
        error: null
    }
}

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {},
    extraReducers: {
        // get comments by itemId
        [fetchCommentsByItemId.pending]: (state, action) => {
            state.itemComments.items = []
            state.itemComments.status = 'loading',
            state.itemComments.error = null
        },
        [fetchCommentsByItemId.fulfilled]: (state, action) => {
            state.itemComments.items = action.payload
            state.itemComments.status = 'loaded'
        },
        [fetchCommentsByItemId.rejected]: (state, action) => {
            state.itemComments.items = []
            state.itemComments.status = 'error'
            state.itemComments.error = action.payload
        },

        // get comments by userId
        [fetchCommentsByUserId.pending]: (state, action) => {
            state.itemComments.items = []
            state.itemComments.totalPages = 0
            state.itemComments.status = 'loading'
            state.itemComments.error = null
        },
        [fetchCommentsByUserId.fulfilled]: (state, action) => {
            state.itemComments.items = action.payload.itemComments
            state.itemComments.totalPages = action.payload.totalPages
            state.itemComments.status = 'loaded'
        },
        [fetchCommentsByUserId.rejected]: (state, action) => {
            state.itemComments.items = []
            state.itemComments.totalPages = 0
            state.itemComments.status = 'error'
            state.itemComments.error = action.payload
        },

        // remove comments
        [fetchRemoveComment.pending]: (state, action) => {
            state.itemComments.items = state.itemComments.items.filter(item => item._id !== action.meta.arg)
            state.itemComments.items.forEach(item => {
                if (item.replies) {
                    item.replies = item.replies.filter(reply => reply._id !== action.meta.arg);
                }
            });

            state.commentsAdmin.items = state.commentsAdmin.items.filter(item => item._id !== action.meta.arg)
            state.commentsAdmin.items.forEach(item => {
                if (item.replies) {
                    item.replies = item.replies.filter(reply => reply._id !== action.meta.arg);
                }
            });
        },

        // get all comments for admin
        [fetchAllCommentsAdmin.pending]: (state, action) => {
            state.commentsAdmin.items = [],
            state.commentsAdmin.totalPages = 0
            state.commentsAdmin.status = 'loading',
            state.commentsAdmin.error = null
        },
        [fetchAllCommentsAdmin.fulfilled]: (state, action) => {
            state.commentsAdmin.items = action.payload.commentsAdmin,
            state.commentsAdmin.totalPages = action.payload.totalPages
            state.commentsAdmin.status = 'loaded'
        },
        [fetchAllCommentsAdmin.rejected]: (state, action) => {
            state.commentsAdmin.items = [],
            state.commentsAdmin.totalPages = 0
            state.commentsAdmin.status = 'error'
            state.commentsAdmin.error = action.payload
        },

    }
})

export const commentsReducer = commentsSlice.reducer
