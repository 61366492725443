import React from 'react'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import StarIcon from '@mui/icons-material/StarBorder';

const tiers = [
  {
    title: 'Частный',
    price: '0.05',
    description: [
      '10 кредитов (2 песни)'
    ],
    buttonText: 'Пополнить счет',
    buttonVariant: 'outlined',
    period: '2 песни'
  },
  {
    title: 'Любительский',
    subheader: 'Самый популярный',
    price: '10',
    description: [
      'Экономия: 20%',
      '2.500 кредитов (500 песен)',
      'Приоритетная email поддержка',
    ],
    buttonText: 'Подписаться',
    buttonVariant: 'contained',
    period: 'мес'
  },
  {
    title: 'Профессиональный',
    price: '30',
    description: [
      'Экономия: 50%',
      '10.000 кредитов (2000 песен)',
      'Приоритетная email поддержка'
    ],
    buttonText: 'Подписаться',
    buttonVariant: 'outlined',
    period: 'мес'
  },
];

export default function Price() {

  return (
    <Container component="main" sx={{ minHeight: '67vh'}}>
        <Typography variant='h3' sx={{ textAlign: 'center', mb: 5 }}>Цены</Typography>
        <Grid container spacing={2} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} md={4}>
              <Card sx={{ width: '100%' }}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                />
                <CardContent sx={{ textAlign: 'center '}}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    ${tier.price}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" sx={{ mb: 2}}>
                    {tier.period}
                  </Typography>
                  {tier.description.map((line) => (
                    <Typography variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
    </Container>
  )
}
