import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper, Box, Stack, Switch, Avatar, TextField, Link, Tooltip, IconButton } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchAllUsersAdmin, fetchUpdateUserAdmin } from '../../redux/slices/users'
import { styled } from '@mui/material/styles';
import { BASE_URL, LIMIT_25 } from '../../config'
import Paginator from '../../components/Paginator'
import AdminMenu from '../../components/AdminMenu'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogContentText from '@mui/material/DialogContentText';
import localAxios from '../../axios'
import { stringToColor } from '../../clientHelpers/stringToColor'
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Modal from '../../components/Modal'
import moment from 'moment';

export default function AUsers() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [showUpdateCreditsModal, setShowUpdateCreditsModal] = useState(false)
  const [updatedUserId, setUpdatedUserId] = useState('');
  const [updatedUserName, setUpdatedUserName] = useState('');
  const [updatedUserCredits, setUpdatedUserCredits] = useState('');

  const isAuth = useSelector(selectIsAuth)
  const userAdmin = useSelector(state => state.auth.data)
  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }
  if (userAdmin && !userAdmin.isAdmin) {
    return <Navigate to='/' />
  }

  useEffect(()=> {
      dispatch(fetchAllUsersAdmin({ page, limit: LIMIT_25 }))
  }, [dispatch, page])
  const users = useSelector(state => state.users.usersAdmin)

  if (!users) return (<LoadingButton />)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  async function updateUserHandler (value) {
    try {
        dispatch(fetchUpdateUserAdmin(value))
        if (showUpdateCreditsModal) {
          setShowUpdateCreditsModal(false)
        }
    } catch(e) {
        console.warn(e)
    }
  }

  function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name[0]}`,
    };
  }

  function dialogContentHandler ({ id, name, credits }) {
    setUpdatedUserId(id)
    setUpdatedUserName(name)
    setUpdatedUserCredits(credits)
    setShowUpdateCreditsModal(true)
    dialogContent()
  }

  function dialogContent () {
    return (
      <>
        <DialogContentText>
          Введите итоговый баланс, который должен быть у пользователя { updatedUserName }
        </DialogContentText>
        <TextField
            sx={{ mt: 2 }}
            required
            id="credits"
            name="credits"
            label="Баланс"
            type="number"
            fullWidth
            value={ updatedUserCredits }
            onChange={(e) => setUpdatedUserCredits(e.target.value)}
        />
      </>
    )
  }

  async function updateBalanceHandler () {
    updateUserHandler({
      userId: updatedUserId,
      credits: +updatedUserCredits
    })

    await localAxios.post(`${BASE_URL}/balance/update-balance-admin`, {
      userId: updatedUserId,
      credits: +updatedUserCredits
     })
  }

  return (
    <>
      <Modal
        open={showUpdateCreditsModal}
        title='Обновление баланса'
        dialogContent={dialogContent}
        onClose={()=> setShowUpdateCreditsModal(false)}
        onCancel={()=> setShowUpdateCreditsModal(false)}
        onSuccess={updateBalanceHandler}
      />
      <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Item>
              <AdminMenu />
            </Item>
          </Grid>
          <Grid item xs={12} md={9}>
              <Item>
                  <Typography variant='h3'>Пользователи</Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Пользователь</TableCell>
                          <TableCell align="right">Кредиты</TableCell>
                          <TableCell align="right" sx={{ width: '100px' }}>Обновление кредитов</TableCell>
                          {/* <TableCell align="right">Блок</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.items.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>
                              <Stack direction={'row'}>
                                <Avatar
                                    {...stringAvatar(row.name)}
                                    src={`${BASE_URL}${row.avatar}`}
                                />
                                <Stack sx={{ ml: 2 }}>
                                  <Link href={`/admin/user/${row._id}`} underline="none" variant='body2' sx={{ fontWeight: 'bold' }}>{row.name}</Link>
                                  <Typography variant='caption' sx={{ mt: 0.5 }}>{row.login}</Typography>
                                  <Typography variant='caption' sx={{ mt: 0.5 }}>Последний визит: { moment(row.lastVisit).format('DD.MM.YYYY HH:mm') }</Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell align="right">
                                {row.credits}
                                <Tooltip title='Обновить баланс' placement='top'>
                                    <IconButton
                                      onClick={()=> {
                                        dialogContentHandler({
                                          id: row._id,
                                          name: row.name,
                                          credits: row.credits
                                        }) }}
                                      sx={{ ml: 1 }}
                                    >
                                        <AddCircleIcon sx={{ color: '#696969', fontSize: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="right">
                              <Switch
                                checked={row.isUpdateCredits}
                                onChange={() => updateUserHandler({
                                  userId: row._id,
                                  isUpdateCredits: !row.isUpdateCredits
                                })}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            </TableCell>
                            {/* <TableCell align="right">
                              <Switch
                                checked={row.isBlocked}
                                onChange={() => updateUserHandler({
                                  userId: row._id,
                                  isBlocked: !row.isBlocked
                                })}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={users.totalPages}
                    />
                  </Box>
              </Item>
          </Grid>
      </Grid>
    </>
  )
}
