import React from 'react'
import { Stack, Link, Box } from '@mui/material';
import { BASE_URL } from '../../config';
import s from './index.module.css'

export default function TagCard({ tag }) {
  return (
    <Box className={s.card}>
      <Stack>
        <Link href={`/style/${tag.toLowerCase()}`}>
          <Box
            component="img"
            src={`${BASE_URL}/uploads/tags/${tag.toLowerCase()}.jpeg`}
            alt={tag}
            sx={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
          />
        </Link>
        <Link
          href={`/style/${tag.toLowerCase()}`}
          variant='body1'
          sx={{ mt: 2, textAlign: 'center', textTransform: 'capitalize' }}
        >
          {tag}
        </Link>
      </Stack>
    </Box>
  )
}
