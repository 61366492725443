import React from 'react'
import { Stack, Typography, FormControl, IconButton, Input } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export default function Paginator({
  page=1,
  totalPages=0,
  setPage
}) {

  function setPageHandler (page) {
    if (page <=0 ) {
      return setPage(1)
    } else if (page > totalPages) {
      return setPage(totalPages)
    } else {
      return setPage(page)
    }
  }

  const leftDisabled = page <= 1
  const rightDisabled = page >= totalPages

  return (
    <Stack direction='row' sx={{ alignItems: 'center' }}>
      <Typography>Страница: </Typography>
      <IconButton size='small' disabled={leftDisabled} onClick={()=> { setPageHandler(page - 1) }}>
        <ArrowLeftIcon />
      </IconButton>
      <FormControl variant="standard">
        <Input
            sx={{
              marginTop: '3px',
              maxWidth: '20px',
              ml: 1,
              '& input[type=number]': {
                  MozAppearance: 'textfield',
                  '&::-webkit-outer-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                  },
                  '&::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                  },
              },
          }}
          type='number'
          size='small'
          required
          id="page"
          name="page"
          value={ page }
          inputProps={{ min: 1, max: totalPages, step: 1 }}
          onChange={(e) => setPageHandler(e.target.value) }
        />
      </FormControl>
      <Typography>/ { totalPages < 1 ? 1 : totalPages }</Typography>
      <IconButton size='small' disabled={rightDisabled} onClick={()=> { setPageHandler(page + 1) }}>
        <ArrowRightIcon />
      </IconButton>
  </Stack>
  )
}
