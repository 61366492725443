import React, { useState, useEffect } from 'react'
import { Typography, Grid, Paper, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchCommentsByUserId } from '../../redux/slices/comments'
import UserInfo from '../../components/UserInfo'
import Comment from '../../components/Comment'
import { LIMIT_10, CABINET } from '../../config'
import Paginator from '../../components/Paginator'
import LoadingButton from '@mui/lab/LoadingButton';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom'

export default function Comments() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const value = Cookies.get('isRegister')
    if (!value) { Cookies.set('isRegister', true, { expires: 3650 }) }
  }, [])

  const user = useSelector(state => state.auth.data)
  const isAuth = useSelector(selectIsAuth)

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }

  useEffect(()=> {
    if (user) {
      dispatch(fetchCommentsByUserId({ userId: user._id, page, limit: LIMIT_10 }))
    }
  }, [user, page])
  const { itemComments } = useSelector(state => state.comments)

  if (!user || !itemComments || itemComments.error) return (<LoadingButton />)


  return (
    <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2 }}>
                  <UserInfo user={user} />
              </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
              <Grid>
                <Paper sx={{ p: 2 }}>
                  <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
                    Комментарии
                  </Typography>
                  { !itemComments.items.length && <Typography variant='body1'>На данный комментариев нет</Typography>}
                  {
                    itemComments.items && itemComments.items.map((item, index) =>
                      item.level === 1 && (
                        <Comment
                          key={index}
                          item={item}
                          app={CABINET}
                        />
                      )
                    )
                  }

                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                      <Paginator
                          page={page}
                          setPage={setPage}
                          totalPages={itemComments.totalPages}
                      />
                  </Box>
                </Paper>
              </Grid>
          </Grid>
        </Grid>
      </Box>
  )
}
