import React, { useState, useEffect } from 'react'
import { Stack, Typography, Box, Link } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchRemoveSong, fetchUpdateStatus, fetchUpdatePublished } from '../../redux/slices/songs'
import { setSelectedSong } from '../../redux/slices/general';
import { WEBSOCKET_BASE_URL } from '../../config';
import { formatSeconds } from '../../clientHelpers/formatSeconds';
import CardMenu from './CardMenu';
import Modal from '../Modal'
import PlayButton from '../PlayButton';
import s from './index.module.css'

export default function SongCard({
  item: initialItem,
  filter
}) {
  const dispatch = useDispatch()
  const [item, setItem] = useState(initialItem)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [showPublishedModal, setShowPublishedModal] = useState(false)
  const [isPublished, setIsPublished] = useState(item.published)

  useEffect(() => {
    const ws = new WebSocket(WEBSOCKET_BASE_URL);

    ws.onopen = () => {
      console.log('Connected to WebSocket');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (item.song.id === data.song.id) {
        setItem((prevItem) => ({
          ...prevItem,
          song: { ...prevItem.song, ...data.song }
        }));
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket');
    };

    return () => {
      ws.close();
    };
  }, []);

  function onRemoveHandler (itemId) {
    dispatch(setSelectedSong({}))
    dispatch(fetchRemoveSong(itemId))
    setShowRemoveModal(false)
  }

  function onChangeStatusHandler (itemId, status) {
    dispatch(setSelectedSong(item.song))
    dispatch(fetchUpdateStatus({ itemId, status }))
  }

  function getPublishedTitle () {
    let publishTitle
    if (item.published) {
      publishTitle = 'Вы уверены, что хотите снять с публикации песню?'
    } else {
      publishTitle = 'Вы уверены, что хотите опубликовать песню на сайте?'
    }
    return publishTitle
  }

  function onChangePublishedHandler (itemId) {
    dispatch(setSelectedSong(item.song))
    dispatch(fetchUpdatePublished({ itemId, isPublished }))
    setShowPublishedModal(false)
  }

  return (
    <>
      <Modal
        open={showRemoveModal}
        title='Вы уверены, что хотите удалить песню?'
        onClose={()=> setShowRemoveModal(false)}
        onCancel={()=> setShowRemoveModal(false)}
        onSuccess={()=> onRemoveHandler(item._id)}
      />
      <Modal
        open={showPublishedModal}
        title={getPublishedTitle()}
        onClose={()=> setShowPublishedModal(false)}
        onCancel={()=> setShowPublishedModal(false)}
        onSuccess={()=> onChangePublishedHandler(item._id)}
      />
      <Stack className={s.card}>
          <Link
            component='button'
            onClick={()=> dispatch(setSelectedSong(item.song)) }
          >
            <img className={s.image} src={`${item.song.image_url}`} />
          </Link>

          <Stack sx={{ padding: '8px' }} direction='row' justifyContent='space-between' >
            <Stack direction={'column'}>
              {
                item.song.metadata?.type === 'concat' && (
                  <Box sx={{ display: 'inline-flex' }}>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        height: '14px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderRadius: '4px',
                        background: '#218e00',
                        color: '#fff',
                        mb: 0.5
                      }}>Финальная версия</Typography>
                    </Box>
                )
              }
              {
                item.song.metadata?.history && (
                  <Box sx={{ display: 'inline-flex' }}>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        height: '14px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderRadius: '4px',
                        background: 'orange',
                        mb: 0.5
                      }}>Часть { item.song.metadata?.history.length + 1}</Typography>
                    </Box>
                )
              }
              <Link
                sx={{ textAlign: 'left', fontWeight: 600 }}
                component='button'
                onClick={()=> dispatch(setSelectedSong(item.song)) }
              >
                { item.song.title }
              </Link>
              <Box sx={{ display: 'inline-flex', mt: 1 }}>
                <Typography variant='caption' color='gray'>{item.song.tags}</Typography>
              </Box>
            </Stack>
            <PlayButton song={item.song} />
          </Stack>

          <Stack sx={{ padding: '8px', paddingTop: 0, paddingRight: '16px' }} direction='row' justifyContent='space-between' >
              <Typography variant='caption'>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
              <Typography variant='caption'>{ item.song.duration ? formatSeconds(item.song?.duration) : '-- : --' }</Typography>
          </Stack>

          {
            !item.song.duration && (
              <Box sx={{
                background: 'orange',
                padding: '4px'
              }}>
                <Typography sx={{
                  fontSize: '10px',
                  lineHeight: '12px',
                  textAlign: 'center',
                  color: 'white'
                }}>
                  Мы еще работаем над Вашей песней, но уже сейчас Вы можете начать слушать то, что мы успели сделать для Вас
                </Typography>
              </Box>
            )
          }


          <CardMenu
            item={item}
            setIsPublished={setIsPublished}
            setShowRemoveModal={setShowRemoveModal}
            setShowPublishedModal={setShowPublishedModal}
            onChangeStatusHandler={onChangeStatusHandler}
          />

      </Stack>
    </>
  )
}
