import React, { useState, useEffect } from 'react'
import { Typography, Grid, Paper, Container, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import UserCard from '../../components/UserCard'
import Paginator from '../../components/Paginator'
import { fetchAllUsers } from '../../redux/slices/users'
import { LIMIT_25 } from '../../config'
import LoadingButton from '@mui/lab/LoadingButton';

export default function Users() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  useEffect(()=> {
      dispatch(fetchAllUsers({ page, limit: LIMIT_25 }))
  }, [dispatch, page])
  const users = useSelector(state => state.users.users)

  if (!users) return (<LoadingButton />)

  return (
      <Container>
        {
          users && (
            <Paper sx={{ padding: 2 }}>
              <Typography variant='h3'>Пользователи</Typography>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                {
                  users.items.map((item, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                        <UserCard user={ item }/>
                    </Grid>
                ))}
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={users.totalPages}
                  />
              </Box>
            </Paper>
          )
        }
      </Container>


  )
}
