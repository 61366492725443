import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function DownloadLink ({ url, filename, children }) {
  const [open, setOpen] = useState(false);

  const handleDownload = (event) => {
    event.preventDefault();
    setOpen(true)

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => console.error('Download error:', err));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  return (
    <>
      <Link
        sx={{ color: '#696969' }}
        href={url}
        onClick={handleDownload}
      >
        {children}
      </Link>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Скачивание началось. Ожидайте
        </Alert>
      </Snackbar>
    </>
  );
}
