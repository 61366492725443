import React from 'react'
import { Stack, Typography, Link, Avatar } from '@mui/material';
import moment from 'moment';
import { BASE_URL, EVENT_TYPES } from '../../config';
import { stringToColor } from '../../clientHelpers/stringToColor'

export default function UserCard({ event, fontSize='body2' }) {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name[0]}`,
    };
  }

  return (
    <>
      <Stack direction='row' sx={{ mt: 2 }}>
          <Link href={`/user/${event.sender.login}`} sx={{ textDecoration: 'none'}}>
            <Avatar
                {...stringAvatar(`${event.sender.name || event.sender.login}`)}
                src={ event.sender.avatar ? `${BASE_URL}${event.sender.avatar}` : ''}
            />
          </Link>
          <Stack sx={{ ml: 1 }}>
            <Typography component='span' variant={fontSize}>
                <Link href={'/user/' + event.sender.login} variant={fontSize}>{ event.sender.name }</Link>
                {' '}{ EVENT_TYPES[event.eventType] }{' '}
                <Link sx={{ ml:0.5 }} href={'/song/' + event.item._id} variant={fontSize}>{ event.item.song.title }</Link>
            </Typography>
            <Typography variant='caption'>{ moment(event.item.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
          </Stack>
      </Stack>
    </>
  )
}
