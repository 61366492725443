import React, { useEffect, useState } from 'react'
import { useWindowDimensions  } from 'react-native';
import { Typography, Grid, Paper, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSongsByUserId, fetchLikesByUserIdAndItemIds } from '../../redux/slices/songs'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchUserInfoByLogin } from '../../redux/slices/users'
import SongCardPublic from '../../components/SongCardPublic'
import SongList from '../../components/SongList'
import Player from '../../components/Player'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { LIMIT_10 } from '../../config'
import Paginator from '../../components/Paginator'
import UserInfo from '../../components/UserInfo'

export default function User() {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const me = useSelector(state => state.auth.data)
  const { login } = useParams()
  const [page, setPage] = useState(1)

  const { width } = useWindowDimensions();
  const isTablet = width > 768

  useEffect(()=> {
    dispatch(fetchUserInfoByLogin(login))
  }, [dispatch, login])
  const user = useSelector(state => state.users.user.items)

  useEffect(()=> {
      if (user && user._id) {
        dispatch(fetchSongsByUserId({ userId: user._id, status: 'published', page, limit: LIMIT_10 }))
      }
  }, [dispatch, user._id, login, page])
  const songs = useSelector(state => state.songs.songs)
  const songListIds = songs.items.map(item => item._id)

  useEffect(() => {
    if (isAuth && me._id) {
      dispatch(fetchLikesByUserIdAndItemIds({ sender: me._id, itemsArray: songListIds }))
    }
  }, [dispatch, JSON.stringify(songs)])
  const likesArray = useSelector(state => state.songs.likes)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  return (
    <Box sx={{ minHeight: '67vh'}}>
      <Player />
      <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <Item>
                  <UserInfo user={user} />
              </Item>
          </Grid>
          <Grid item xs={12} md={8}>
              <Item>
                  <Typography variant='h3'>Песни</Typography>
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                      {
                        songs.items.map((item, index) => (
                          <Grid item xs={12} sm={12} md={12} key={index}>
                              {
                                isTablet ? (
                                  <SongList
                                    item={ item }
                                    likesArray={ likesArray }
                                    initialSongList='songs'
                                    columns={2}
                                  />
                                ) : (
                                  <SongCardPublic
                                    item={ item }
                                    likesArray={ likesArray }
                                    initialSongList='songs'
                                  />
                                )
                              }
                          </Grid>
                      ))}
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={songs.totalPages}
                    />
                  </Box>
              </Item>
          </Grid>
      </Grid>
    </Box>
  )
}
