import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import { LIMIT_10 } from "../../config";

export const fetchAllBalancesAdmin = createAsyncThunk(
    'comments/fetchAllBalancesAdmin',
    async function ({ page = 1, limit = LIMIT_10 }, { rejectWithValue }) {
        try {
            const res = await axios.get(`/balance/admin/get-all-balances-admin/${page}/${limit}`)

            if (res.status !== 200) {
                throw new Error('Server error: fetch all balance - admin')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const initialState = {
    balancesAdmin: {
        items: [],
        totalPages: 0,
        status: 'loading',
        error: null
    }
}

const balancesSlice = createSlice({
    name: 'balances',
    initialState,
    reducers: {},
    extraReducers: {
        // get all balances for admin
        [fetchAllBalancesAdmin.pending]: (state, action) => {
            state.balancesAdmin.items = [],
            state.balancesAdmin.totalPages = 0
            state.balancesAdmin.status = 'loading',
            state.balancesAdmin.error = null
        },
        [fetchAllBalancesAdmin.fulfilled]: (state, action) => {
            state.balancesAdmin.items = action.payload.balancesAdmin,
            state.balancesAdmin.totalPages = action.payload.totalPages
            state.balancesAdmin.status = 'loaded'
        },
        [fetchAllBalancesAdmin.rejected]: (state, action) => {
            state.balancesAdmin.items = [],
            state.balancesAdmin.totalPages = 0
            state.balancesAdmin.status = 'error'
            state.balancesAdmin.error = action.payload
        },

    }
})

export const balancesReducer = balancesSlice.reducer
