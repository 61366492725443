import React from 'react'
import { Stack, Box, IconButton, Tooltip } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { ARCHIVE, FAVORITE, GENERAL, SUNO_API_URL, BASE_URL, LIMIT } from '../../../config';
export default function CardMenu({ item, setShowRemoveModal }) {
    return (
        <>
            <Stack direction='row' sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#eee'
            }}>
                <Box>
                </Box>
                <Box>
                    <ThumbUpIcon fontSize='small' color={ item.status === FAVORITE ? 'primary' : undefined } />
                    <ThumbDownIcon sx={{ ml: 1, width: 18, height: 18 }} color={ item.status === ARCHIVE ? 'primary' : undefined } />
                    <RadioButtonCheckedIcon sx={{ ml: 1 }} fontSize='small' color={ item.published ? 'primary' : undefined } />
                </Box>
                <Box>
                    <Tooltip title='Удалить полностью' placement='top'>
                        <IconButton onClick={()=> setShowRemoveModal(true)}>
                            <DeleteIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
        </>
    )
}
