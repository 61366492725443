import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchLogin = createAsyncThunk(
    'auth/fetchLogin',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/login', params);
            return data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue({ message: 'Network error' });
            }
        }
        }
    );

export const fetchAuthMe = createAsyncThunk(
    'auth/fetchAuthMe',
    async () => {
        const { data } = await axios.get('/auth/me')
        return data
    }
)

export const fetchRegister = createAsyncThunk(
    'auth/fetchRegister',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axios.post('/auth/register', params)
            return data
        } catch (error) {
            if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
            } else {
            return rejectWithValue({ message: 'Network error' });
            }
        }

    }
)

const initialState = {
    data: null,
    status: 'loading',
    error: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.data = null
        }
    },
    extraReducers: {
        [fetchLogin.pending]: (state) => {
            state.data = null;
            state.status = 'loading';
        },
        [fetchLogin.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = 'loaded';
        },
        [fetchLogin.rejected]: (state, action) => {
            state.data = null;
            state.status = 'error';
            state.error = action.payload || { message: 'Login: unknown error' };
        },

        [fetchAuthMe.pending]: (state, action) => {
            state.data = null
            state.status = 'loading'
        },
        [fetchAuthMe.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = 'loaded'
        },
        [fetchAuthMe.rejected]: (state, action) => {
            state.data = null
            state.status = 'error'
        },

        [fetchRegister.pending]: (state, action) => {
            state.data = null
            state.status = 'loading'
        },
        [fetchRegister.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = 'loaded'
        },
        [fetchRegister.rejected]: (state, action) => {
            state.data = null
            state.status = 'error',
            state.error = action.payload || { message: 'Register: unknown error' };
        }
    }
})

export const selectIsAuth = (state) => Boolean(state.auth.data)

export const { logout } = authSlice.actions

export const authReducer = authSlice.reducer
