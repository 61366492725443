import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useDispatch } from 'react-redux';
import { fetchUpdateAudioLink, fetchUpdateDuration } from '../../redux/slices/songs';
import { updateAudioUrl } from '../../clientHelpers/updateAudioUrl'
import Backdrop from '../Backdrop'

export default function Player() {
    const dispatch = useDispatch()
    const { playingSong } = useSelector(state => state.general)
    const playerRef = useRef();
    const [validAudioLink, setValidAudioLink] = useState()
    const [backdropVisible, setBackdropVisible] = useState(false)

    async function canPlayAudio(urlToCheck) {
        setBackdropVisible(true)

        return new Promise((resolve) => {
            const audio = new Audio();
            audio.src = urlToCheck;

            const onCanPlayThrough = () => {
                audio.removeEventListener('canplaythrough', onCanPlayThrough);
                audio.removeEventListener('error', onError);
                resolve(true);
            };

            const onError = () => {
                audio.removeEventListener('canplaythrough', onCanPlayThrough);
                audio.removeEventListener('error', onError);
                resolve(false);
            };

            audio.addEventListener('canplaythrough', onCanPlayThrough, { once: true });
            audio.addEventListener('error', onError, { once: true });

            // Для улучшенной поддержки Firefox можно попытаться предварительно загрузить аудио
            audio.preload = 'auto';
            audio.load();
        });
    }

    useEffect(() => {
        if (playingSong && playingSong.audio_url) {
            if (playingSong.audio_url.endsWith('.mp3')) {
                return setValidAudioLink(playingSong.audio_url)
            } else {

                canPlayAudio(playingSong.audio_url).then(result => {
                    if (result) {
                        console.log('Audio can be played.');
                        setValidAudioLink(playingSong.audio_url)
                        setBackdropVisible(false)
                    } else {
                        console.log('Audio cannot be played and will update.');
                        setValidAudioLink(updateAudioUrl(playingSong.audio_url));
                        dispatch(fetchUpdateAudioLink({ songId: playingSong.id, link: updateAudioUrl(playingSong.audio_url) }))
                        setBackdropVisible(false)
                    }
                });
            }
        } else {
            setValidAudioLink('')
        }
    }, [JSON.stringify(playingSong)])


    useEffect(() => {
        if (!playingSong.duration) {
            const player = playerRef.current?.audio?.current;
            if (player) {
                function handleLoadedMetadata () {
                    dispatch(fetchUpdateDuration({ songId: playingSong.id, duration: player.duration }))
                }
                player.addEventListener('loadedmetadata', handleLoadedMetadata);
                return () => {
                    player.removeEventListener('loadedmetadata', handleLoadedMetadata);
                };
            }
        }
    }, [JSON.stringify(playingSong)])

    return (
        <>
            <Backdrop open={backdropVisible} />
            <AudioPlayer
                src={validAudioLink}
                ref={playerRef}
                customAdditionalControls={[]}
                layout='horizontal'
                style={{ marginBottom: '24px' }}
            />
        </>
    )
}
