import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper, Box, Stack, Avatar } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchAllBalancesAdmin } from '../../redux/slices/balance'
import { styled } from '@mui/material/styles';
import { LIMIT_50, BASE_URL } from '../../config'
import Paginator from '../../components/Paginator'
import AdminMenu from '../../components/AdminMenu'
import { stringToColor } from '../../clientHelpers/stringToColor'
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

export default function ABalance() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [balanceByDate, setBalanceByDate] = useState([])

  const isAuth = useSelector(selectIsAuth)
  const userAdmin = useSelector(state => state.auth.data)

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }
  if (userAdmin && !userAdmin.isAdmin) {
    return <Navigate to='/' />
  }

  useEffect(()=> {
      dispatch(fetchAllBalancesAdmin({ page, limit: LIMIT_50 }))
  }, [dispatch, page])
  const balance = useSelector(state => state.balance.balancesAdmin)

  useEffect(()=> {
    if (balance && balance.items.length) {
      const updatedBalance = transformSongsWithDateHeaders(balance)
      setBalanceByDate(updatedBalance)
    }
  }, [balance, JSON.stringify(balance)])

  const transformSongsWithDateHeaders = (balance) => {
    return balance.items.reduce((acc, balance) => {
      const balanceDate = new Date(balance.createdAt).toLocaleDateString();

      if (!acc.length || acc[acc.length - 1].balanceDate !== balanceDate) {
        acc.push({ type: 'date', balanceDate });
      }

      acc.push({ ...balance, type: 'balance', balanceDate });
      return acc;
    }, []);
  };

  function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name[0]}`,
    };
  }

  if (!balance) return (<LoadingButton />)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  return (
    <>
      <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Item>
              <AdminMenu />
            </Item>
          </Grid>
          <Grid item xs={12} md={9}>
              <Item>
                  <Typography variant='h3' sx={{ mb: 2 }}>Баланс</Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">

                      <TableHead>
                        <TableRow>
                          <TableCell>Пользователь</TableCell>
                          <TableCell>Описание</TableCell>
                          <TableCell align='center'>Дата</TableCell>
                          <TableCell align='center'>Сумма</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>

                        { balanceByDate.map((row, index) => (
                          row.type === 'date' ? (
                            <TableRow key={`date-${index}`}>
                              <TableCell colSpan={4} sx={{ background: '#dbecfc' }}>
                                <Typography variant="h6" color='primary'>{row.balanceDate}</Typography>
                              </TableCell>
                            </TableRow>
                          ) :(
                            <TableRow
                              key={row._id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell>
                                <Stack direction={'row'} alignItems={'center'}>
                                  <Avatar
                                      {...stringAvatar(row.user.name)}
                                      src={`${BASE_URL}${row.user.avatar}`}
                                  />
                                  <Typography variant='body2' sx={{ ml: 2 }}>{row.user.name}</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Typography variant='body2'>{ row.desc }</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='body2'>{ moment(row.createdAt).format('DD.MM.YYYY HH:mm') }</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant='body2'>{ row.sum }</Typography>
                              </TableCell>
                             
                            </TableRow>
                          )
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={balance.totalPages}
                    />
                  </Box>
              </Item>
          </Grid>
      </Grid>
    </>
  )
}
