import React from 'react'
import { Box, IconButton } from '@mui/material';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayingSong, setSelectedSong } from '../../redux/slices/general';
import { fetchUpdatePlays } from '../../redux/slices/songs';

export default function PlayButton({ song, style }) {
  const dispatch = useDispatch()
  const { playingSong } = useSelector(state => state.general)

  function onPlayHandler (song) {
      dispatch(setPlayingSong(song))
      dispatch(setSelectedSong(song))
      dispatch(fetchUpdatePlays({ songId: song.id }))
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center'
    }}>
      {
        song.audio_url === playingSong.audio_url ? (
          playingSong && playingSong.audio_url ? (
            <IconButton color="primary" onClick={() => dispatch(setPlayingSong('')) }>
              <StopCircleIcon fontSize='large' sx={style} />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={() => onPlayHandler(song) }>
              <PlayCircleIcon fontSize='large' sx={style} />
            </IconButton>
          )
        ) : (
          <IconButton color="primary" onClick={() => onPlayHandler(song) }>
            <PlayCircleIcon fontSize='large' sx={style} />
          </IconButton>
        )
      }
    </Box>
  )
}
