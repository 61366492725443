import React from 'react'
import { useWindowDimensions, Text } from 'react-native';
import { Stack, Typography, Link, Avatar, Divider, Grid } from '@mui/material';
import moment from 'moment';
import { BASE_URL } from '../../config'
import { stringToColor } from '../../clientHelpers/stringToColor'
import { formatSeconds } from '../../clientHelpers/formatSeconds'
import PlayButton from '../PlayButton';
import s from './index.module.css'
import SongStats from '../SongStats'

export default function SongListPublic({ item, likesArray, initialSongList }) {
  const { width } = useWindowDimensions();
  const isDesktop = width > 1024

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        position: 'absolute',
        zIndex: 1,
        width: 50,
        height: 50,
        border: '4px solid #fff'
      },
      children: `${name[0]}`,
    };
  }

  return (
    <Stack sx={{ mt: -2 }} direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'}>
          <Stack justifyContent={'center'}>
            <Avatar
              {...stringAvatar(`${item.user.name}`)}
              src={`${BASE_URL}${item.user.avatar}`}
            />
            <img className={s.image} src={`${item.song.image_url}`} />
          </Stack>

          {
            isDesktop ? (
              <Stack sx={{ ml: 2 }} justifyContent={'center'}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Link href={`/song/${item._id}`} underline="none">{item.song.title}</Link>
                      </Typography>
                  </Grid>
                  <Grid item sx={{ ml: 1 }}>
                    <Typography variant="caption" sx={{ color: 'gray',  mt: 0.5 }}>
                      {item.song.tags}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant='body2'>
                      <Link href={`/user/${item.user.login}`} underline="none">{ item.user.name }</Link>
                    </Typography>
                  </Grid>
                  <Grid item sx={{ ml: 1 }}>
                    <Typography variant='caption' sx={{ color: 'gray', mt: 0.5 }}>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Stack sx={{ ml: 2, width: 'auto' }} justifyContent={'center'}>
                <Typography variant='body1' sx={{ fontWeight: 600 }}>
                  <Link href={`/song/${item._id}`}  underline="none">{ item.song.title }</Link>
                </Typography>
                <Typography variant='body2' sx={{ mt: 0.5 }}>
                  <Link href={`/user/${item.user.login}`} underline="none">{ item.user.name }</Link>
                </Typography>
                <Typography variant='caption' color='gray' sx={{ mt: 0.5 }}>
                  {item.song.tags}
                </Typography>
                <Typography variant='caption' color='gray' sx={{ mt: 0.5 }}>{ moment(item.song.created_at).format('DD.MM.YYYY HH:mm') }</Typography>
              </Stack>
            )
          }
        </Stack>

        <Stack direction={'row'}>
          <SongStats
            item={item}
            likesArray={likesArray}
            initialSongList={initialSongList}
          />

          <Stack sx={{ ml: 3 }} justifyContent={'center'}>
                <PlayButton song={item.song} />
                <Typography variant='body2' sx={{ ml: 1 }}>{ item.song.duration ? formatSeconds(item.song?.duration) : '-- : --' }</Typography>
          </Stack>
        </Stack>
    </Stack>
  )
}
