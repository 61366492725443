import { configureStore } from '@reduxjs/toolkit'
import { generalReducer } from './slices/general'
import { authReducer } from './slices/auth'
import { songsReducer } from './slices/songs'
import { usersReducer } from './slices/users'
import { commentsReducer } from './slices/comments'
import { eventsReducer } from './slices/events'
import { balancesReducer } from './slices/balance'

const store = configureStore({
    reducer: {
        general: generalReducer,
        auth: authReducer,
        songs: songsReducer,
        users: usersReducer,
        comments: commentsReducer,
        events: eventsReducer,
        balance: balancesReducer
    },
    devTools: false
})

export default store
