import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter} from 'react-router-dom'
import { routes } from './routes';
import store from './redux/store';
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if (process.env.NODE_ENV === 'production') disableReactDevTools();

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        {routes()}
      </Provider>
    </BrowserRouter>
  );
};

export default App;
