import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLogin, selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import { Paper, Typography, Box, Grid, Link, Button, TextField, Alert } from '@mui/material'

export default function Login() {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const isAuth = useSelector(selectIsAuth)
  const dispatch = useDispatch()

  async function onSubmit(e) {
    e.preventDefault();

    const values = {
      login: login.trim().toLowerCase(),
      password
    };

    try {
      const data = await dispatch(fetchLogin(values));

      if (!data.payload.success) {
        return setError(data.payload.message);
      }

      if ('token' in data.payload) {
        window.localStorage.setItem('token', data.payload.token);
      }
    } catch (error) {
      alert(error.message || 'Произошла ошибка при авторизации');
    }
  }

  if (isAuth) {
    return <Navigate to='/cabinet/studio' />
  }

  return (
    <Paper sx={{ padding: 2, display: 'flex', justifyContent: 'center', height: '67vh'}}>
      <Box sx={{ maxWidth: '500px' }}>
        <Typography component="h1" variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
          Вход
        </Typography>
        {
          error && (<Alert severity="error" sx={{ mb: 1 }}>{ error }</Alert>)
        }
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          onChange={(e) => setLogin(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Пароль"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onSubmit}
        >
          Вход
        </Button>
        <Grid container>
          {/* <Grid item xs={12}>
            <Link href="/" variant="body2">
              Забыли пароль?
            </Link>
          </Grid> */}
          <Grid item>
            <Link href="/signup" variant="body2">
              {"У вас нет аккаунта? Зарегистрируйтесь!"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
