import React from 'react'
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { BASE_URL } from '../../config'
import localAxios from '../../axios'
import { selectIsAuth } from '../../redux/slices/auth'
import { updateLikeCount  } from '../../redux/slices/songs'
import ChatIcon from '@mui/icons-material/Chat';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

export default function SongStats({
  item,
  likesArray,
  initialSongList,
  canAddLike
}) {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const user = useSelector(state => state.auth.data)
  const { width } = useWindowDimensions();
  const isDesktop = width > 1024

  async function addLike (e, item) {
    e.preventDefault()
    try {
      const data = await localAxios.post(`${BASE_URL}/song/like`, {
        itemId: item._id,
        sender: user._id,
        receiver: item.user._id
      })
      dispatch(updateLikeCount({  itemId: item._id, likeCount: data.data.likeCount, initialSongList }));
    } catch(e) {
        console.warn(e)
    }
  }

  const likesIds = likesArray.items.map(item => item.item)
  const likeColor = likesIds.includes(item._id) ? '#1976d2' : '#696969'

  return (
      <Stack direction={'row'}>
        <Stack sx={{ ml: 3, minWidth: '130px' }} direction={'row'} alignItems={'center'}>
          {
            isAuth && user.isAdmin && (
              <>
                <PlayCircleFilledIcon sx={{ color: '#696969', fontSize: '16px', mr: 1 }} />
                <Typography variant='body2' sx={{ mr: 1 }}>{ item.plays || 0 }</Typography>
              </>
            )
          }
          <IconButton href={`/song/${item._id}`}>
            <ChatIcon sx={{ color: '#696969', fontSize: '16px' }} />
          </IconButton>
          <Typography variant='body2' sx={{ mr: 1 }}>{ item.comments || 0 }</Typography>
          {
            isAuth ? (
              <IconButton size="small" onClick={(e) => addLike(e, item)}>
                <ThumbUpIcon sx={{ fontSize: '16px', color: likeColor }} />
              </IconButton>
            ) : (
              <ThumbUpIcon sx={{ color: '#696969', fontSize: '16px', mr: 0.5 }} />
            )
          }
          <Typography variant='body2'>{ item.likes }</Typography>
        </Stack>
      </Stack>
  )
}
