import React from 'react'
import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

export default function Modal({
    open=false,
    title='',
    onClose,
    onCancel,
    onSuccess,
    dialogContent,
    isCancelButton=true,
    isSuccessButton=true,
    cancelButtonText='Нет',
    successButtonText='Да'
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>

            {
                dialogContent && (
                    <DialogContent>
                        {
                            dialogContent()
                        }
                    </DialogContent>
                )
            }

            <DialogActions>
                { isCancelButton && (<Button onClick={onCancel}>{cancelButtonText}</Button>) }
                { isSuccessButton && (<Button onClick={onSuccess}>{successButtonText}</Button>) }
            </DialogActions>
        </Dialog>
    );
}
