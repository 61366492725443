import React, { useEffect, useState } from 'react'
import { useWindowDimensions  } from 'react-native';
import { Typography, Grid, Paper, Container, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import SongCardPublic from '../../components/SongCardPublic'
import SongListPublic from '../../components/SongListPublic'
import Player from '../../components/Player'
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchSongsList, fetchLikesByUserIdAndItemIds } from '../../redux/slices/songs'
import { selectIsAuth } from '../../redux/slices/auth'
import { LIMIT_25 } from '../../config'
import Paginator from '../../components/Paginator'

export default function Users() {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)
  const user = useSelector(state => state.auth.data)
  const [page, setPage] = useState(1)
  const { width } = useWindowDimensions();
  const isTablet = width > 768

  useEffect(()=> {
    dispatch(fetchSongsList({ page, limit: LIMIT_25 }))
  }, [dispatch, page])
  const songsList = useSelector(state => state.songs.songsList)
  const songListIds = songsList.items.map(item => item._id)

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchLikesByUserIdAndItemIds({ sender: user._id, itemsArray: songListIds }))
    }
  }, [dispatch, JSON.stringify(songsList)])
  const likesArray = useSelector(state => state.songs.likes)

  if (!songsList) return (<LoadingButton />)

  return (
    <Container>
        <Player />
        <Paper sx={{ padding: 2 }}>
          <Typography variant='h3'>Авторские песни</Typography>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {
              songsList.items.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                    {
                      isTablet ? (
                        <SongListPublic
                          item={ item }
                          likesArray={ likesArray }
                          initialSongList='songsList'
                        />
                      ) : (
                        <SongCardPublic
                          item={ item }
                          likesArray={ likesArray }
                          initialSongList='songsList'
                        />
                      )
                    }
                </Grid>
            ))}
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Paginator
                  page={page}
                  setPage={setPage}
                  totalPages={songsList.totalPages}
              />
          </Box>

        </Paper>
    </Container>

  )
}
