import React from 'react';
import {Routes, Route} from 'react-router-dom'
import Layout from './main/Layout';

// general
import Home from './main/Home'
import User from './main/User'
import Users from './main/Users'
import Signup from './main/Singup';
import Login from './main/Login';
import ForgotPassword from './main/ForgotPassword';
import ResetPassword from './main/ResetPassword';
import Price from './main/Price';
import Song from './main/Song';
import Songs from './main/Songs';
import Styles from './main/Styles';

// cabinet
import Cabinet from './cabinet/Cabinet';
import CreateSong from './cabinet/CreateSong';
import EditProfile from './cabinet/EditProfile';
import Events from './cabinet/Events';
import Comments from './cabinet/Comments';

// admin
import AUsers from './admin/AUsers'
import AUser from './admin/AUser'
import ASongs from './admin/ASongs'
import AComments from './admin/AComments'
import ABalance from './admin/ABalance'
import AVoice from './admin/AVoice'

export function routes () {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >
        {/* General routes */}
        <Route index element={<Home />} />
        <Route path='signup' element={<Signup />} />
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='price' element={<Price />} />
        <Route path='user/:login' element={<User />} />
        <Route path='users' element={<Users />} />
        <Route path='songs' element={<Songs />} />
        <Route path='song/:itemId' element={<Song />} />
        <Route path='style/:tag' element={<Styles />} />

        {/* Cabinet routes */}
        <Route path='cabinet' element={<Cabinet/>} />
        <Route path='cabinet/studio' element={<CreateSong/>} />
        <Route path='cabinet/profile' element={<EditProfile/>} />
        <Route path='cabinet/events' element={<Events/>} />
        <Route path='cabinet/comments' element={<Comments/>} />

        {/* Admin routes */}
        <Route path='admin/users' element={<AUsers />} />
        <Route path='admin/user/:userId' element={<AUser />} />
        <Route path='admin/songs' element={<ASongs />} />
        <Route path='admin/comments' element={<AComments />} />
        <Route path='admin/balance' element={<ABalance />} />
        <Route path='admin/voice' element={<AVoice />} />

      </Route>
    </Routes>
  );
};
