import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import { Typography, Box, Grid, Paper } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import UserInfo from '../../components/UserInfo'
import Cookies from 'js-cookie';
import { fetchEvents } from '../../redux/slices/events'
import Event from '../../components/Event'
import { LIMIT_10 } from '../../config'
import Paginator from '../../components/Paginator'
import localAxios from '../../axios'

export default function Cabinet() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const value = Cookies.get('isRegister')
    if (!value) { Cookies.set('isRegister', true, { expires: 3650 }) }
  }, [])

  const user = useSelector(state => state.auth.data)
  const isAuth = useSelector(selectIsAuth)

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }

  async function updateReadEvents (userId) {
      await localAxios.patch('event/update-read', { userId })
  }

  useEffect(() => {
    if (user) {
      dispatch(fetchEvents({ userId: user._id, page, limit: LIMIT_10 }))
    }
  }, [dispatch, user, page])
  const { events } = useSelector(state => state.events)

  useEffect(() => {
    if (user) {
      updateReadEvents(user._id)
    }
  }, [user])

  if (!user || events.error) return (<LoadingButton />)

  return (
    <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2 }}>
                  <UserInfo user={user} />
              </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
              <Grid>
                <Paper sx={{ p: 2 }}>

                  <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
                    События
                  </Typography>
                  { !events.items.length && <Typography variant='body1'>На данный момент событий нет</Typography>}
                  {
                    events.items.map((event, index) => (
                        <Event key={index} event={event} />
                    ))
                  }

                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                      <Paginator
                          page={page}
                          setPage={setPage}
                          totalPages={events.totalPages}
                      />
                  </Box>
                </Paper>
              </Grid>
          </Grid>
        </Grid>
      </Box>
  )
}
