import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import { LIMIT_10 } from "../../config";

export const fetchUserInfoByLogin = createAsyncThunk(
    'users/fetchUserInfoByLogin',
    async function (login, { rejectWithValue }) {
        try {
            const res = await axios.get(`/user/user-info-by-login/${login}`)

            if (res.status !== 200) {
                throw new Error('Server error: get user by login')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchAllUsers = createAsyncThunk(
    'users/fetchAllUsers',
    async function ({ page = 1, limit = LIMIT_10 }, { rejectWithValue }) {
        try {
            const res = await axios.get(`/user/${page}/${limit}`)

            if (res.status !== 200) {
                throw new Error('Server error: get all user')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchAllUsersAdmin = createAsyncThunk(
    'users/fetchAllUsersAdmin',
    async function ({ page = 1, limit = LIMIT_10, query = {} }, { rejectWithValue }) {
        try {
            let res
            if (Object.keys(query).length) {
                res = await axios.get(`/user/admin/get-users-by-query/${limit}/${JSON.stringify(query)}`)
            } else {
                res = await axios.get(`/user/admin/${page}/${limit}`)
            }

            if (res.status !== 200) {
                throw new Error('Server error: get all user')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchDailyUserUpdate = createAsyncThunk(
    'users/fetchDailyUserUpdate',
    async function ({ login, defaultCredits }, { rejectWithValue }) {
        try {
            const res = await axios.patch(`/user/daily-user-update`, { login, defaultCredits })

            if (res.status !== 200) {
                throw new Error('Server error: patch daily user update')
            }

            return res.data
        } catch (error) {
            console.error('Error in fetchDailyUserUpdate:', error);
            if (error.response) {
                return rejectWithValue(error.response.data);
            } else if (error.request) {
                return rejectWithValue('No response from server');
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const fetchUpdateUserAdmin = createAsyncThunk(
    'users/fetchUpdateUserAdmin',
    async function (value, { rejectWithValue}) {
        try {
            const res = await axios.patch('/user/admin/update-user', value)

            if(res.status !== 200) {
                throw new Error('Server error: fetch fetchUpdatePlays songs')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const initialState = {
    users: {
        items: [],
        totalUsers: 0,
        totalPages: 0,
        status: 'loading',
        error: null
    },
    user: {
        items: [],
        status: 'loading',
        error: null
    },
    usersAdmin: {
        items: [],
        totalUsers: 0,
        totalPages: 0,
        status: 'loading',
        error: null
    },
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: {
        // get all users
        [fetchAllUsers.pending]: (state, action) => {
            state.users.items = [],
            state.users.totalUsers = 0,
            state.users.totalPages = 0,
            state.users.status = 'loading',
            state.users.error = null
        },
        [fetchAllUsers.fulfilled]: (state, action) => {
            state.users.items = action.payload.users,
            state.users.totalUsers = action.payload.totalUsers,
            state.users.totalPages = action.payload.totalPages,
            state.users.status = 'loaded'
        },
        [fetchAllUsers.rejected]: (state, action) => {
            state.users.items = [],
            state.users.totalUsers = 0,
            state.users.totalPages = 0,
            state.users.status = 'error',
            state.users.error = action.payload
        },

        // get user info by id
        [fetchUserInfoByLogin.pending]: (state, action) => {
            state.user.items = []
            state.user.status = 'loading',
            state.user.error = null
        },
        [fetchUserInfoByLogin.fulfilled]: (state, action) => {
            state.user.items = action.payload
            state.user.status = 'loaded'
        },
        [fetchUserInfoByLogin.rejected]: (state, action) => {
            state.user.items = []
            state.user.status = 'error'
            state.user.error = action.payload
        },

        // get all users for admin
        [fetchAllUsersAdmin.pending]: (state, action) => {
            state.usersAdmin.items = [],
            state.usersAdmin.totalUsers = 0,
            state.usersAdmin.totalPages = 0,
            state.usersAdmin.status = 'loading',
            state.usersAdmin.error = null
        },
        [fetchAllUsersAdmin.fulfilled]: (state, action) => {
            state.usersAdmin.items = action.payload.usersAdmin,
            state.usersAdmin.totalUsers = action.payload.totalUsers,
            state.usersAdmin.totalPages = action.payload.totalPages,
            state.usersAdmin.status = 'loaded'
        },
        [fetchAllUsersAdmin.rejected]: (state, action) => {
            state.usersAdmin.items = [],
            state.usersAdmin.totalUsers = 0,
            state.usersAdmin.totalPages = 0,
            state.usersAdmin.status = 'error',
            state.usersAdmin.error = action.payload
        },

        [fetchUpdateUserAdmin.fulfilled]: (state, action) => {
            state.usersAdmin.items = state.usersAdmin.items.map(item =>
                item._id === action.meta.arg.userId ? action.payload.user : item)
        },
    }
})

export const usersReducer = usersSlice.reducer
