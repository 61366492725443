export function updateAudioUrl(url) {
if (url.indexOf('.mp3') > 0) {
    return url
} else {
    url = url.replace('audiopipe', 'cdn1')
    url = url.replace('?item_id=', '')
    url = url + '.mp3'
    return url
}
}
  