import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { Typography, Link, MenuItem, Paper, ListItemIcon, ListItemText } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import PaidIcon from '@mui/icons-material/Paid';
import MicIcon from '@mui/icons-material/Mic';

export default function AdminMenu() {
  const isAuth = useSelector(selectIsAuth)

  return (
    <>
      {
        isAuth && (
          <>
            <Typography variant='h5' color='error'>Кабинет администратора</Typography>
            <Paper sx={{ border: '1px solid #1976D9', mt: 2, pt: 0.5, pb: 0.5 }}>
                <MenuItem sx={{ pt: 1, pb: 1,  mb: 0.5 }} component={Link} href='/admin/users'>
                  <ListItemIcon>
                    <PeopleAltIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#1976D9'}}>
                    Пользователи
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ pt: 1, pb: 1,  mb: 0.5 }} component={Link} href='/admin/songs'>
                  <ListItemIcon>
                    <LibraryMusicIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#1976D9'}}>
                    Песни
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ pt: 1, pb: 1,  mb: 0.5 }} component={Link} href='/admin/comments'>
                  <ListItemIcon>
                    <ChatIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#1976D9'}}>
                    Комментарии
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ pt: 1, pb: 1,  mb: 0.5 }} component={Link} href='/admin/balance'>
                  <ListItemIcon>
                    <PaidIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#1976D9'}}>
                    Баланс
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ pt: 1, pb: 1,  mb: 0.5 }} component={Link} href='/admin/voice'>
                  <ListItemIcon>
                    <MicIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#1976D9'}}>
                    Получить голос
                  </ListItemText>
                </MenuItem>
            </Paper>
          </>
        )
      }

    </>
  )
}
