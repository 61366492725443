import React, { useEffect, useState } from 'react'
import { Typography, Grid, Paper, Box, Stack, Switch, Avatar, TextField, Link, Tooltip, IconButton } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { fetchAllCommentsAdmin } from '../../redux/slices/comments'
import { styled } from '@mui/material/styles';
import { BASE_URL, LIMIT_10, ADMIN } from '../../config'
import Paginator from '../../components/Paginator'
import AdminMenu from '../../components/AdminMenu'
import localAxios from '../../axios'
import { stringToColor } from '../../clientHelpers/stringToColor'
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate } from 'react-router-dom'
import Comment from '../../components/Comment'

export default function AComments() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  const isAuth = useSelector(selectIsAuth)
  const userAdmin = useSelector(state => state.auth.data)
  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }
  if (userAdmin && !userAdmin.isAdmin) {
    return <Navigate to='/' />
  }

  useEffect(()=> {
      dispatch(fetchAllCommentsAdmin({ page, limit: LIMIT_10 }))
  }, [dispatch, page])
  const comments = useSelector(state => state.comments.commentsAdmin)

  if (!comments) return (<LoadingButton />)

  const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

  return (
    <>
      <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Item>
              <AdminMenu />
            </Item>
          </Grid>
          <Grid item xs={12} md={9}>
              <Item>
                  <Typography variant='h3'>Комментарии</Typography>
                  {
                    comments.items && comments.items.map((item, index) =>
                      item.level === 1 && (
                        <Comment
                          key={index}
                          item={item}
                          app={ADMIN}
                        />
                      )
                    )
                  }
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Paginator
                      page={page}
                      setPage={setPage}
                      totalPages={comments.totalPages}
                    />
                  </Box>
              </Item>
          </Grid>
      </Grid>
    </>
  )
}
