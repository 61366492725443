import React from 'react'
import { Typography, Stack, Tooltip, IconButton, Box } from '@mui/material'
import SongCard from '../../../components/SongCard'
import SongCardSkeleton from '../../../components/SongCard/Skeleton'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FAVORITE, GENERAL, ARCHIVE } from '../../../config'
import Paginator from '../../../components/Paginator'

const filters = [
    {
        title: 'Все, кроме тех, что не понравились',
        filter: GENERAL,
        icon: FormatAlignJustifyIcon
    },
    {
        title: 'Понравились',
        filter: FAVORITE,
        icon: ThumbUpIcon
    },
    {
        title: 'Не понравились',
        filter: ARCHIVE,
        icon: ThumbDownIcon
    },
    {
        title: 'Опубликованные песни',
        filter: 'published',
        icon: RadioButtonCheckedIcon
    }
]

export default function SongsList({
    songs,
    filter,
    setFilter,
    page,
    setPage
}) {
    return (
        <>
            <Typography variant="h5" sx={{mb: 2}}>Песни</Typography>
            <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end', mb: 1 }}>
                <Typography variant="body2" sx={{ mr: 1 }}>Показать: </Typography>
                {
                    filters.map((item, index) => (
                        <Tooltip title={item.title} placement='top' key={index}>
                            <IconButton onClick={()=> { setFilter(item.filter) }}>
                                <item.icon sx={{ width: 18, height: 18 }} color={ filter === item.filter ? 'primary' : undefined } />
                            </IconButton>
                        </Tooltip>
                    ))
                }
            </Stack>

            {
                songs.items.map((item, index) => (
                    item.song.audio_url ? (
                        <SongCard
                            key={index}
                            item={item}
                            filter={filter}
                        />
                    ) : (
                        <SongCardSkeleton key={index} />
                    )
                ))
            }

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Paginator 
                    page={page}
                    setPage={setPage}
                    totalPages={songs.totalPages}
                />
            </Box>
        </>
    )
}
