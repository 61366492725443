import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth, fetchAuthMe } from '../../redux/slices/auth'
import { useNavigate, Navigate } from 'react-router-dom'
import localAxios from '../../axios'
import { Alert, Container, Typography, Box, Stack, Avatar, Paper, Input, Button, TextField, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { fetchUserInfoByLogin } from '../../redux/slices/users'
import { BASE_URL } from '../../config'
import { stringToColor } from '../../clientHelpers/stringToColor'
import UserInfo from '../../components/UserInfo'
import Cookies from 'js-cookie';

export default function EditProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)

  useEffect(() => {
    const value = Cookies.get('isRegister')
    if (!value) { Cookies.set('isRegister', true, { expires: 3650 }) }
  }, [])

  if (!window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' />
  }

  const user = useSelector(state => state.auth.data)

  useEffect(()=> {
    if (user) {
      setName(user.name)
      setAvatar(user.avatar)
    }
  }, [user?.name])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [avatar, setAvatar] = useState('')
  const inputFileRef = useRef()
  const [error, setError] = useState('')

  const handleChangeAvatarFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
  
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${Date.now()}-${Math.round(Math.random() * 1E9)}.${fileExtension}`;
      formData.append('image', new File([file], uniqueFileName));
  
      const { data } = await localAxios.post('/upload', formData);
      setAvatar(data.url);

      event.target.value = '';
      event.target.removeEventListener('change', handleChangeAvatarFile);
      
    } catch (error) {
      console.warn(error);
      alert('Ошибка при загрузке файла');
    }
  };
  

  const onClickRemoveImage = async () => {
    try {
      if (avatar) {
        const filename = avatar ? avatar.split('/').pop() : user.avatar.split('/').pop()
        await deleteAvatarFile(filename);
        setAvatar('');       
      }
    } catch (error) {
      console.warn(error);
      alert('Ошибка при удалении файла');
    }
  };
  
  const deleteAvatarFile = async (filename) => {
    try {
        await localAxios.delete('/upload', { data: { filename } });
    } catch (error) {
        console.warn(error);
        alert('Ошибка при удалении файла');
    }
  };

  async function onSubmit (e) {
    if (e) { e.preventDefault() }
    try {
      const values = {
        name: name || user.name,
        email: email || user.email,
        avatar
      }

      const response = await localAxios.patch(`${BASE_URL}/auth/me`, values)
      const data = response.data;

      if (!data.success) {
        setError(data.message);
        return
      }

      inputFileRef.current.value = ''; // Очищаем значение input (чтобы можно было загрузить тот же файл снова, если нужно)
      inputFileRef.current.addEventListener('change', handleChangeAvatarFile); // Повторно добавляем обработчик

      setAvatar('')
      
      dispatch(fetchUserInfoByLogin(user.login))
      dispatch(fetchAuthMe())

    } catch (error) {
        console.warn(error);
        if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
        } else {
            alert('Ошибка при сохранении профиля пользователя');
        }
    }
  }

  if (!user) return (
    <Container component="main">
      <LoadingButton loading variant="text"></LoadingButton>
    </Container>
  )

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2 }}>
                <UserInfo user={user} />
            </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
            <Grid>
              <Paper sx={{ p: 2 }}>
                <Typography component="h1" variant="h4" sx={{ textAlign: 'center' }}>
                  Профиль
                </Typography>

                <>
                  <Stack direction='row' sx={{ textAlign: 'left', mt: 4 }}>
                    <Avatar
                      {...stringAvatar(`${user.name}`)}
                      src={`${BASE_URL}${avatar}`}
                    />
                    <Stack direction='column' sx={{ ml: 4}}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        sx={{ mb: 2, width: '180px' }}
                        disabled={!!user.avatar && !!avatar}
                        onClick={() => inputFileRef.current.click()}
                      >
                          Загрузить аватар
                      </Button>
                      <Input
                        inputRef={inputFileRef}
                        type='file'
                        hidden
                        onChange={handleChangeAvatarFile}
                      />

                      { (avatar  || user.avatar) && (
                        <Button 
                          color='error' 
                          disabled={!avatar}
                          onClick={onClickRemoveImage}
                        >
                          Удалить аватар
                        </Button>
                      )}
                    </Stack>
                  </Stack>

                  {
                    error && (<Alert severity="error" sx={{ mt: 2 }}>{ error }</Alert>)
                  }
                  <TextField
                    sx={{ mt: 4 }}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    label="Имя для публикации"
                    defaultValue={ user?.name }
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    sx={{ mt: 2 }}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    disabled
                    defaultValue={ user?.email }
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, width: 200 }}
                    onClick={(e) => {
                      e.preventDefault()
                      onSubmit(e)
                    }}
                  > Сохранить
                  </Button>
                </>
              </Paper>
            </Grid>
        </Grid>
      </Grid>
    </Box>
  )

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 200,
        height: 200,
        fontSize: '56px'
      },
      children: `${name[0]}`,
    };
  }
}
