import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import { LIMIT_10 } from "../../config";

export const fetchEvents = createAsyncThunk(
    'comments/fetchEvents',
    async function ({ userId, page = 1, limit = LIMIT_10 }, { rejectWithValue }) {
        try {
            const res = await axios.get(`/event/get-events/${userId}/${page}/${limit}`)

            if (res.status !== 200) {
                throw new Error('Server error: fetch events')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const initialState = {
    events: {
        items: [],
        status: 'loading',
        error: null
    }
}

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: {
        // get events by user id
        [fetchEvents.pending]: (state, action) => {
            state.events.items = []
            state.events.totalPages = 0
            state.events.status = 'loading'
            state.events.error = null
        },
        [fetchEvents.fulfilled]: (state, action) => {
            state.events.items = action.payload.events
            state.events.totalPages = action.payload.totalPages
            state.events.status = 'loaded'
        },
        [fetchEvents.rejected]: (state, action) => {
            state.events.items = []
            state.songs.totalPages = 0
            state.events.status = 'error'
            state.events.error = action.payload
        },
    }
})

export const eventsReducer = eventsSlice.reducer
