import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, selectIsAuth, fetchAuthMe } from '../../../redux/slices/auth'
import { fetchDailyUserUpdate, fetchUpdateUserAdmin } from '../../../redux/slices/users'
import { fetchEvents } from '../../../redux/slices/events'
import { AppBar, Container, Toolbar, Button, Popover, Box, Link, Avatar, IconButton, Menu, MenuItem, Typography, Stack, Badge } from '@mui/material'
import { BASE_URL, SUNO_API_URL, DEFAULT_CREDITS } from '../../../config'
import { stringToColor } from '../../../clientHelpers/stringToColor'
import axios from 'axios'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import localAxios from '../../../axios'
import Event from '../../../components/Event'

export default function TopMenu () {
    const isAuth = useSelector(selectIsAuth)
    const dispatch = useDispatch()

    const [credits, setCredits] = useState()
    const [newEventCount, setNewEventCount] = useState()

    useEffect(() => {
        dispatch(fetchAuthMe())
    }, []);
    const user = useSelector(state => state.auth.data)

    // обновляем кредиты
    useEffect(() => {
        if (isAuth && user && user.isUpdateCredits) {
            const currentDate = new Date();
            const updateCredits = new Date(user.updateCredits);

            if ((!updateCredits || currentDate.toDateString() !== updateCredits.toDateString()) && (user.credits < DEFAULT_CREDITS)) {
                dispatch(fetchDailyUserUpdate({
                    login: user.login,
                    defaultCredits: DEFAULT_CREDITS
                }))

            }
        }
    }, [isAuth, JSON.stringify(user)])

    // обновляем дату последнего визита раз в period минут
    useEffect(() => {
        if (isAuth && user && user._id && user.lastVisit) {
            const period = 15 * 60 // 15 минут
            const timeLeft = Math.floor(new Date().getTime() / 1000) - Math.floor(new Date(user.lastVisit).getTime() / 1000)

            if (timeLeft > period) {
                dispatch(fetchUpdateUserAdmin({
                    userId: user._id,
                    lastVisit: new Date()
                }))
                dispatch(fetchAuthMe())
            }
        }
    }, [isAuth, JSON.stringify(user)])

    async function getCountEvents (userId) {
        const countEvents = await localAxios.get('/event/get-new-event-count/' + userId)
        setNewEventCount(countEvents.data.countEvents)
    }

    async function updateReadEvents (userId) {
        await localAxios.patch('event/update-read', { userId })
    }

    useEffect(() => {
        if (isAuth && user) {
            getCountEvents(user._id)
        }
    }, [user])

    useEffect(() => {
        if (isAuth && user) {
            dispatch(fetchEvents({ userId: user._id, page: 1, limit: 5 }))
        }
    }, [user])
    const { events } = useSelector(state => state.events)

    useEffect(() => {
        if (isAuth && user && user.isAdmin) {
            axios.get(`${SUNO_API_URL}/api/get_limit`).then(({ data }) => {
                setCredits(data.credits_left)
            }).catch(err => {
                console.warn(err)
            })
        }
    }, [isAuth])

    function logoutHandler () {
        dispatch(logout())
        window.localStorage.removeItem('token')
        closeUserMenuHandler()
    }

    const [anchorElUser, setAnchorElUser] = useState(null);

    const openUserMenuHandler = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const closeUserMenuHandler = () => {
        setAnchorElUser(null);
    };

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${name[0]}`
        };
    }

    const [anchorElEvents, setAnchorElEvents] = React.useState(null);

    const openEventsHandler = async (event) => {
        setAnchorElEvents(event.currentTarget);
        try {
            await updateReadEvents(user._id);
            await getCountEvents(user._id);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onCloseEventHandler = () => {
        setAnchorElEvents(null);
    };

    const openEvents = Boolean(anchorElEvents);
    const id = open ? 'simple-popover' : undefined;

    return (
        <AppBar position='sticky' color='inherit'>
            <Container maxWidth='lg'>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box>
                        <Button href="/">Главная</Button>
                    </Box>
                    <Box>
                    { !isAuth ?
                        (
                            <>
                                <Button href="/login">Вход</Button>
                                <Button href="/signup">Регистрация</Button>
                            </>
                        ) : (
                            <Stack direction='row' sx={{ alignItems: 'center' }}>
                                <Stack direction='column'>
                                    {/* <Typography>Баланс: <Link href='/price'>{user.credits} кредитов</Link></Typography> */}
                                    <Typography>Баланс: {user.credits} кредитов</Typography>
                                    {
                                        user.isAdmin && !!credits && (
                                            <Typography variant='caption' color='gray'>Всего в системе: {credits} кредитов</Typography>
                                        )
                                    }
                                </Stack>
                                <Badge color="error" badgeContent={newEventCount} max={99}>
                                    <IconButton aria-describedby={id} onClick={openEventsHandler} sx={{ ml: 2, p: 0 }}>
                                        <NotificationsNoneIcon />
                                    </IconButton>
                                </Badge>
                                <Popover
                                    id={id}
                                    open={openEvents}
                                    anchorEl={anchorElEvents}
                                    onClose={onCloseEventHandler}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Box sx={{ minHeight: '200px', width: '300px', p: 2, pt: 0 }}>
                                    { !events.items.length && (
                                        <Box sx={{ textAlign: 'center', mt: 2 }}>
                                            <Typography variant='body1'>Событий нет</Typography>
                                        </Box>
                                    )}
                                    {
                                        events.items.map((event, index) => (
                                            <Event key={index} event={event} fontSize='caption' />
                                        ))
                                    }
                                        <Box sx={{ textAlign: 'center', mt: 2 }}>
                                            <Link href='/cabinet/events'>Все события</Link>
                                        </Box>
                                    </Box>

                                </Popover>
                                <IconButton onClick={openUserMenuHandler} sx={{ ml: 3, p: 0 }}>
                                    <Avatar
                                        alt={user.name}
                                        {...stringAvatar(`${user.name || user.login}`)}
                                        src={`${BASE_URL}${user.avatar}`}
                                    />
                                </IconButton>
                                <IconButton onClick={openUserMenuHandler}>
                                    <ArrowDropDownIcon />
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    keepMounted
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={Boolean(anchorElUser)}
                                    onClose={closeUserMenuHandler}
                                >
                                    <MenuItem onClick={closeUserMenuHandler}>
                                        <NavLink to={`/user/${user.login}`}>
                                            <Typography textAlign="center">Моя страница</Typography>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem onClick={closeUserMenuHandler}>
                                        <NavLink to={'/cabinet/studio'}>
                                            <Typography textAlign="center">Студия</Typography>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem onClick={closeUserMenuHandler}>
                                        <NavLink to={'/cabinet'}>
                                            <Typography textAlign="center">Кабинет</Typography>
                                        </NavLink>
                                    </MenuItem>
                                    {
                                        user.isAdmin && (
                                            <MenuItem onClick={closeUserMenuHandler}>
                                                <NavLink to={'/admin/users'}>
                                                    <Typography textAlign="center" color={'error'}>Админ</Typography>
                                                </NavLink>
                                            </MenuItem>
                                        )
                                    }
                                    <MenuItem onClick={logoutHandler}>
                                        <NavLink to={'/'}>
                                            <Typography textAlign="center">Выход</Typography>
                                        </NavLink>
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        )
                    }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
