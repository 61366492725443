import { Typography } from "@mui/material"

export const settingHelp = () => {
    return (
        <Typography variant="body2" component="div" sx={{ mt: 2 }}>
            Разбейте текст произведения на куплеты и припевы. Размер текста не должен быть более 3000 символов. <br />
            Вставьте специальные теги перед соответствующими блоками:<br /><br />

            [intro] - инструментальное вступление<br />
            [verse] - куплет<br />
            [chorus] - припев<br />
            [bridge] - переход между куплетом и припевом<br />
            [instrumental] - инструментальная вставка<br />
            [outro] - инструментальная вставка перед завершением<br />
            [end] - конец песни<br /><br />

            Помимо этих тегов вы так же можете экспериментировать с любыми другими словами, которые могли бы подчеркнуть исполнение вашей песни.
            Например,<br /><br />

            [slow] - медленно<br />
            [fast] - быстро<br />
            [forte] - громко<br />
            [piano] - тихо<br />
            [male] - мужской голос<br />
            [female] - женский голос<br /><br />

            и другие. Комбинируйте и получайте нужный результат. Главное, все теги должны быть написаны на английском языке.<br /><br />

            Давайте рассмотрим в качестве примера стихотворение "Hope" is the thing with feathers (Emily Dickinson).<br />
            Стихотворение может быть на любом языке.<br /><br />

            Оригинал:<br /><br />

            “Hope” is the thing with feathers -<br />
            That perches in the soul -<br />
            And sings the tune without the words -<br />
            And never stops - at all -<br /><br />

            And sweetest - in the Gale - is heard -<br />
            And sore must be the storm -<br />
            That could abash the little Bird<br />
            That kept so many warm -<br /><br />

            I’ve heard it in the chillest land -<br />
            And on the strangest Sea -<br />
            Yet - never - in Extremity,<br />
            It asked a crumb - of me.<br /><br />

            Изначально у него 3 строфы. Мы решили, что третья строфа будет припевом. Припев будет звучать после каждого куплета и в конце будет 
            повторяться после инструментальной вставки. Кроме того, мы решили указать, что песня должна быть исполнена женским голосом и тихо.
            Последнее повторение припева - громко. Давайте представим теперь этот текст так, как мы должны добавить его в настройки:<br /><br />

            [intro]<br />
            [verse][female][piano]<br /><br />

            “Hope” is the thing with feathers -<br />
            That perches in the soul -<br />
            And sings the tune without the words -<br />
            And never stops - at all -<br /><br />

            [chorus]<br />
            I’ve heard it in the chillest land -<br />
            And on the strangest Sea -<br />
            Yet - never - in Extremity,<br />
            It asked a crumb - of me.<br /><br />

            [verse]<br />
            And sweetest - in the Gale - is heard -<br />
            And sore must be the storm -<br />
            That could abash the little Bird<br />
            That kept so many warm -<br /><br />

            [chorus]<br />
            I’ve heard it in the chillest land -<br />
            And on the strangest Sea -<br />
            Yet - never - in Extremity,<br />
            It asked a crumb - of me.<br /><br />

            [instrumental]<br /><br />

            [chorus][forte]<br />
            I’ve heard it in the chillest land -<br />
            And on the strangest Sea -<br />
            Yet - never - in Extremity,<br />
            It asked a crumb - of me.<br /><br />

            [end]<br /><br />

            Теги не являются обязательными. Вы можете ставить или не ставить их. Это только ваше решение.   <br /> <br />

            И помните, что вы работаете с ИИ. Все ваши настройки - это пожелания к песне, а не строгое правило. <br /> <br />

            В поле Стили укажите стиль песни (рок, поп и др.). Кроме того, здесь вы так же можете указать свои пожелания к песне, например, указать мужской или женский 
            голос должен исполнять эту песню. Прописывайте стили через запятую. Все стили указываются так же на английском языке. По умолчанию установлен стиль pop.<br /> <br />

            В поле Название песни укажите соответственно название песни. По умолчанию, если вы не заполните это поле, мы возьмем первые три слова вашей песни.<br /> <br />

            Если вы делаете продолжение песни, то у вас появятся дополнительные поля для ввода минут и секунд, с которых вы хотите сделать продолжение песни.
            По умолчанию продолжение будет генерироваться с последней секунды исходной песни. Если вы укажите свои данные, то все, начиная с указанного времени, будет
            заменено на новый сгенерированный вариант. Оригинальная песня при этом изменена не будет. Вы получите новую генерацию с продолжением. В дальнейшем
            вы сможете соединить оригинал и продолжение.
        
        </Typography>
    )
}                      
