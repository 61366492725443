import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import localAxios from '../../axios'
import { LIMIT_10, ARCHIVE, GENERAL, FAVORITE } from "../../config";

export const fetchUpdateDuration = createAsyncThunk(
    'songs/fetchUpdateDuration',
    async function ({songId, duration}, { rejectWithValue}) {
        const values = {
            songId,
            duration
        }

        try {
            const res = await localAxios.patch('/song/update-duration', values)

            if(res.status !== 200) {
                throw new Error('Server error: fetch fetchUpdateDuration songs')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchUpdateAudioLink = createAsyncThunk(
    'songs/fetchUpdateAudioLink',
    async function ({songId, link}, { rejectWithValue}) {
        const values = {
            songId,
            link
        }

        try {
            const res = await localAxios.patch('/song/update-audiolink', values)

            if(res.status !== 200) {
                throw new Error('Server error: fetch fetchUpdateAudioLink songs')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchUpdateStatus = createAsyncThunk(
    'songs/fetchUpdateStatus',
    async function ({itemId, status}, { rejectWithValue}) {
        const values = {
            itemId,
            status
        }

        try {
            const res = await localAxios.patch('/song/update-status', values)

            if(res.status !== 200) {
                throw new Error('Server error: fetch fetchUpdateStatus songs')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchUpdatePublished = createAsyncThunk(
    'songs/fetchUpdatePublished',
    async function ({itemId, isPublished}, { rejectWithValue}) {
        const values = {
            itemId,
            published: isPublished
        }

        try {
            const res = await localAxios.patch('/song/update-published', values)

            if(res.status !== 200) {
                throw new Error('Server error: fetch fetchUpdatePublished songs')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchUpdatePlays = createAsyncThunk(
    'songs/fetchUpdatePlays',
    async function (songId, { rejectWithValue}) {
        try {
            const res = await localAxios.patch('/song/update-plays', songId)

            if(res.status !== 200) {
                throw new Error('Server error: fetch fetchUpdatePlays songs')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchInProgressSongs = createAsyncThunk(
    'songs/fetchInProgressSongs',
    async function (userId, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-inprogress-songs/' + userId)

            if(res.status !== 200) {
                throw new Error('Server error: fetch inprogress songs')
            }

            return res.data.songs
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchSongsByUserId = createAsyncThunk(
    'songs/fetchSongsByUserId',
    async function ({ userId, status, page = 1, limit = LIMIT_10 }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-songs-by-userid/' + userId + '/' + status + '/' + page + '/' + limit)

            if (res.status !== 200) {
                throw new Error('Server error: fetch get songs by userId')
            }

            return res.data
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchSongsList = createAsyncThunk(
    'songs/fetchSongsList',
    async function ({ page, limit }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-songs-list/' + page + '/' + limit)

            if (res.status !== 200) {
                throw new Error('Server error: fetch get songs list')
            }

            return res.data
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchRemoveSong = createAsyncThunk(
    'songs/fetchRemoveSong',
    async function (itemId, { rejectWithValue }) {
        try {
            await localAxios.delete('/song/remove-song/' + itemId)
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchSongById = createAsyncThunk(
    'songs/fetchSongById',
    async function (itemId, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-song-by-id/' + itemId)

            if (res.status !== 200) {
                throw new Error('Server error: fetch song by id')
            }

            return res.data.song
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchLikesByUserIdAndItemIds = createAsyncThunk(
    'songs/fetchLikesByUserIdAndItemIds',
    async function ({ sender, itemsArray }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-likes-by-userid-and-itemids/' + sender + '/' + itemsArray.join())

            if (res.status !== 200) {
                throw new Error('Server error: fetch get likes by userId')
            }

            return res.data
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchLikes = createAsyncThunk(
    'songs/fetchLikes',
    async function ({ status, userId, page, limit }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-likes/' + status + '/' + userId + '/' + page + '/' + limit)

            if (res.status !== 200) {
                throw new Error('Server error: fetch get likes')
            }

            return res.data
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchAllSongsListAdmin = createAsyncThunk(
    'songs/fetchAllSongsListAdmin',
    async function ({ page, limit }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/admin/' + page + '/' + limit)

            if (res.status !== 200) {
                throw new Error('Server error: fetch get songs list')
            }

            return res.data
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchTopTags = createAsyncThunk(
    'songs/fetchTopTags',
    async function ({ limit }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-top-tags/' + limit)

            if (res.status !== 200) {
                throw new Error('Server error: fetch get top tags')
            }

            return res.data
        } catch(error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchSongsByTag = createAsyncThunk(
    'songs/fetchSongsByTag',
    async function ({ page, limit, tag }, { rejectWithValue }) {
        try {
            const res = await localAxios.get('/song/get-songs-by-tag/' + page + '/' + limit + '/' + tag)

            if (res.status !== 200) {
                throw new Error('Server error: fetch songs by tag')
            }

            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const initialState = {
    song: {
        items: [],
        status: 'loading',
        error: null
    },
    songs: {
        items: [],
        totalSongs: 0,
        totalPages: 0,
        status: 'loading',
        error: null
    },
    inProgressSongs: {
        items: [],
        status: 'loading',
        error: null
    },
    songsList: {
        items: [],
        totalSongs: 0,
        totalPages: 0,
        status: 'loading',
        error: null
    },
    likes: {
        items: [],
        totalLikes: 0,
        totalPages: 0,
        status: 'loading',
        error: null
    },
    songsAdmin: {
        items: [],
        totalSongs: 0,
        totalPages: 0,
        status: 'loading',
        error: null
    },
    topTags: {
        items: [],
        status: 'loading',
        error: null
    },
}

const songsSlice = createSlice({
    name: 'songs',
    initialState,
    reducers: {
        updateLikeCount: (state, action) => {
          const { itemId, likeCount, initialSongList } = action.payload;
          let song
          if (initialSongList === 'songs') {
            song = state.songs.items.find(item => item._id === itemId);
          }
          if (initialSongList === 'songsList') {
            song = state.songsList.items.find(item => item._id === itemId);
          }
          if (song) {
            song.likes = likeCount;
          }
        },
    },
    extraReducers: {
        [fetchSongById.pending]: (state, action) => {
            state.song.items = [],
            state.song.status = 'loading',
            state.song.error = null
        },
        [fetchSongById.fulfilled]: (state, action) => {
            state.song.items = action.payload
            state.song.status = 'loaded'
        },
        [fetchSongById.rejected]: (state, action) => {
            state.song.items = [],
            state.song.status = 'error',
            state.song.error = action.payload
        },

        [fetchSongsByUserId.pending]: (state, action) => {
            state.songs.items = [],
            state.songs.totalSongs = 0,
            state.songs.totalPages = 0,
            state.songs.status = 'loading',
            state.songs.error = null
        },
        [fetchSongsByUserId.fulfilled]: (state, action) => {
            state.songs.items = action.payload.songs,
            state.songs.totalSongs = action.payload.totalSongs,
            state.songs.totalPages = action.payload.totalPages,
            state.songs.status = 'loaded'
        },
        [fetchSongsByUserId.rejected]: (state, action) => {
            state.songs.items = [],
            state.songs.totalSongs = 0,
            state.songs.totalPages = 0,
            state.songs.status = 'error',
            state.songs.error = action.payload
        },

        [fetchInProgressSongs.pending]: (state, action) => {
            state.inProgressSongs.items = [],
            state.inProgressSongs.status = 'loading',
            state.inProgressSongs.error = null
        },
        [fetchInProgressSongs.fulfilled]: (state, action) => {
            state.inProgressSongs.items = action.payload,
            state.inProgressSongs.status = 'loaded'
        },
        [fetchInProgressSongs.rejected]: (state, action) => {
            state.inProgressSongs.items = [],
            state.inProgressSongs.status = 'error',
            state.inProgressSongs.error = action.payload
        },

        // remove song
        [fetchRemoveSong.pending]: (state, action) => {
            state.songs.items = state.songs.items.filter(item => item._id !== action.meta.arg)
        },

        [fetchSongsList.pending]: (state, action) => {
            state.songsList.items = [],
            state.songsList.totalSongs = 0,
            state.songsList.totalPages = 0,
            state.songsList.status = 'loading',
            state.songsList.error = null
        },
        [fetchSongsList.fulfilled]: (state, action) => {
            state.songsList.items = action.payload.songsList,
            state.songsList.totalSongs = action.payload.totalSongs,
            state.songsList.totalPages = action.payload.totalPages,
            state.songsList.status = 'loaded'
        },
        [fetchSongsList.rejected]: (state, action) => {
            state.songsList.items = [],
            state.songsList.totalSongs = 0,
            state.songsList.totalPages = 0,
            state.songsList.status = 'error',
            state.songsList.error = action.payload
        },

        [fetchUpdateDuration.fulfilled]: (state, action) => {
            state.songs.items = state.songs.items.map(item => item.song.id === action.meta.arg.songId ? action.payload.song : item)
        },

        [fetchUpdateAudioLink.fulfilled]: (state, action) => {
            state.songs.items = state.songs.items.map(item => item.song.id === action.meta.arg.songId ? action.payload.song : item)
        },

        [fetchUpdatePlays.fulfilled]: (state, action) => {
            const { id, plays } = action.payload;
            state.songs.items = state.songs.items.map(item =>
                item._id === id ? { ...item, plays } : item
            );
            state.songsList.items = state.songsList.items.map(item =>
                item._id === id ? { ...item, plays } : item
            );
        },

        [fetchUpdateStatus.fulfilled]: (state, action) => {
            if (action.meta.arg.status === ARCHIVE) {
                state.songs.items = state.songs.items.filter(item => item._id !== action.meta.arg.itemId)
            } else {
                state.songs.items = state.songs.items.map(item => item._id === action.meta.arg.itemId ? action.payload.song : item)
            }
        },

        [fetchUpdatePublished.fulfilled]: (state, action) => {
            state.songs.items = state.songs.items.map(item => item._id === action.meta.arg.itemId ? action.payload.song : item)
        },

        [fetchLikesByUserIdAndItemIds.pending]: (state, action) => {
            state.likes.items = [],
            state.likes.status = 'loading',
            state.likes.error = null
        },
        [fetchLikesByUserIdAndItemIds.fulfilled]: (state, action) => {
            state.likes.items = action.payload.likes,
            state.likes.status = 'loaded'
        },
        [fetchLikesByUserIdAndItemIds.rejected]: (state, action) => {
            state.likes.items = [],
            state.likes.status = 'error',
            state.likes.error = action.payload
        },

        [fetchLikes.pending]: (state, action) => {
            state.likes.items = [],
            state.likes.totalLikes = 0,
            state.likes.totalPages = 0,
            state.likes.status = 'loading',
            state.likes.error = null
        },
        [fetchLikes.fulfilled]: (state, action) => {
            state.likes.items = action.payload.likes,
            state.likes.totalLikes = action.payload.totalLikes,
            state.likes.totalPages = action.payload.totalPages,
            state.likes.status = 'loaded'
        },
        [fetchLikes.rejected]: (state, action) => {
            state.likes.items = [],
            state.likes.totalLikes = 0,
            state.likes.totalPages = 0,
            state.likes.status = 'error',
            state.likes.error = action.payload
        },

        // get all users for admin
        [fetchAllSongsListAdmin.pending]: (state, action) => {
            state.songsAdmin.items = [],
            state.songsAdmin.totalSongs = 0,
            state.songsAdmin.totalPages = 0,
            state.songsAdmin.status = 'loading',
            state.songsAdmin.error = null
        },
        [fetchAllSongsListAdmin.fulfilled]: (state, action) => {
            state.songsAdmin.items = action.payload.songsAdmin,
            state.songsAdmin.totalSongs = action.payload.totalSongs,
            state.songsAdmin.totalPages = action.payload.totalPages,
            state.songsAdmin.status = 'loaded'
        },
        [fetchAllSongsListAdmin.rejected]: (state, action) => {
            state.songsAdmin.items = [],
            state.songsAdmin.totalSongs = 0,
            state.songsAdmin.totalPages = 0,
            state.songsAdmin.status = 'error',
            state.songsAdmin.error = action.payload
        },

        // get top tags
        [fetchTopTags.pending]: (state, action) => {
            state.topTags.items = [],
            state.topTags.status = 'loading',
            state.topTags.error = null
        },
        [fetchTopTags.fulfilled]: (state, action) => {
            state.topTags.items = action.payload.topTags,
            state.topTags.status = 'loaded'
        },
        [fetchTopTags.rejected]: (state, action) => {
            state.topTags.items = [],
            state.topTags.status = 'error',
            state.topTags.error = action.payload
        },

        // get songs by tag
        [fetchSongsByTag.pending]: (state, action) => {
            state.songsList.items = [],
            state.songsList.totalSongs = 0,
            state.songsList.totalPages = 0,
            state.songsList.status = 'loading',
            state.songsList.error = null
        },
        [fetchSongsByTag.fulfilled]: (state, action) => {
            state.songsList.items = action.payload.songsList,
            state.songsList.totalSongs = action.payload.totalSongs,
            state.songsList.totalPages = action.payload.totalPages,
            state.songsList.status = 'loaded'
        },
        [fetchSongsByTag.rejected]: (state, action) => {
            state.songsList.items = [],
            state.songsList.totalSongs = 0,
            state.songsList.totalPages = 0,
            state.songsList.status = 'error',
            state.songsList.error = action.payload
        },
    }
})

export const { updateLikeCount } = songsSlice.actions;
export const songsReducer = songsSlice.reducer
