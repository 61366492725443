import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    form: {
        isCustomMode: true,
        isInstrumental: false,
        isExtend: false,
        prompt: '',
        tags: '',
        title: '',
        audio_id: '',
        version: 'v3.5',
        continueAt: 0
    },
    extendSong: {},
    playingSong: {},
    selectedSong: {}
}

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setForm(state, action) {
            state.form = action.payload;
        },
        setExtendSong(state, action) {
            state.extendSong = action.payload;
        },
        setPlayingSong(state, action) {
            state.playingSong = action.payload;
        },
        setSelectedSong(state, action) {
            state.selectedSong = action.payload;
        }
    }
})

export const { setForm, setExtendSong, setPlayingSong, setSelectedSong } = generalSlice.actions
export const generalReducer = generalSlice.reducer
