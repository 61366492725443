import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Stack, Avatar, TextField, IconButton, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { BASE_URL, CABINET } from '../../config'
import { stringToColor } from '../../clientHelpers/stringToColor';
import { fetchAddComment, fetchCommentsByItemId } from '../../redux/slices/comments'
import LoadingButton from '@mui/lab/LoadingButton';

export default function SendComment({
    user: receiver,
    commentId = false,
    level = 1,
    rootCommentId,
    item,
    app
}) {
    const dispatch = useDispatch()
    const { itemId } = useParams()
    const [text, setText] = useState('')
    const [error, setError] = useState('')
    const sender = useSelector(state => state.auth.data)

    async function addCommentHandler () {
        if (!text.length || !text.trim()) {
            setError(true)
            return
        }
        try {
            const values = {
                sender: sender._id,
                receiver: level === 1 ? receiver._id : receiver,
                item: app === CABINET ? item : itemId,
                text: text.trim(),
                level
            }

            if (commentId && rootCommentId) {
                values.rootCommentId = rootCommentId
                values.commentId = commentId
            }

            await dispatch(fetchAddComment(values))
            setText('')

            if (app === CABINET) {
                window.location.href = window.location.href
            } else {
                await dispatch(fetchCommentsByItemId(itemId))
            }

        } catch (error) {
            console.warn(error)
            alert('Ошибка при сохранении комментария')
        }
    }

    if (!sender) return (<LoadingButton />)

    return (
        <>
            {
                error && (<Alert sx={{ mb: 2, mt: 2 }}severity="error">Напишите что-нибудь...</Alert>)
            }
            <Stack direction="row" alignItems='center' sx={{ mt: 2 }}>
                <Avatar
                    {...stringAvatar(`${sender.name}`)}
                    src={`${BASE_URL}${sender.avatar}`}
                />
                <TextField
                    fullWidth
                    required
                    multiline
                    rows={2}
                    id="text"
                    name="text"
                    label="Введите текст комментария"
                    sx={{ ml: 1 }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <IconButton
                    sx={{ ml: 1, color: '#1976D2' }}
                    size='large'
                    onClick={addCommentHandler}
                >
                    <SendIcon />
                </IconButton>
            </Stack>
        </>
    )

    // Functions

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${name[0]}`,
        };
    }

}
