import React from 'react'
import { Container, Box, Link, Typography, Alert } from '@mui/material'
import { useWindowDimensions  } from 'react-native';
import { useLocation  } from 'react-router-dom'
import { BASE_URL } from '../../../config'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../../redux/slices/auth'
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import s from './index.module.css'

export default function Header() {
  const location = useLocation()

  const { width } = useWindowDimensions();
  const isTablet = width > 768

  const headerImage = 'tabletBanner.jpg'

  const isHomePage = location.pathname === '/'
  const isAuth = useSelector(selectIsAuth)
  const createSongButton = isAuth ? '/cabinet/studio' : '/signup'

  return (
    <>
      {
        isHomePage && (
          <Box style={{ backgroundImage: `url(${BASE_URL}/uploads/${headerImage}` }} className={s.bg}>
            <Box className={s.gradient}>
              <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                <Typography variant='h2' component='h1'>Создай свою песню!</Typography>
                <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <SpatialAudioOffIcon />
                  <Typography variant='body1' sx={{ml:1}}>Песня на свои стихи</Typography>
                </Box>

                <Box sx={{ mt: 2, ml: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <LibraryMusicIcon />
                  <Typography variant='body1' sx={{ml:1}}>Десятки музыкальных стилей</Typography>
                </Box>

                <Box sx={{ mt: 2, ml: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AccessTimeFilledIcon />
                  <Typography variant='body1' sx={{ml:1}}>Всего за считанные секунды</Typography>
                </Box>

                <Link
                  variant='h5'
                  underline='none'
                  color='white'
                  href={createSongButton}
                  sx={{ mt: 8, width: '222px', border: '1px solid white', borderRadius: '32px', padding: '8px 24px' }}
                >Создать песню</Link>
              </Container>
              <Box style={{ backgroundImage: `url(${BASE_URL}/uploads/background-white-color.png` }} className={s.after}></Box>
            </Box>
          </Box>
        )
      }

      {/* {
        isAuth && (
          <Container className={s.container} sx={{ pl: 2, pr: 2 }}>
            <Alert severity="error" sx={{ mt: 3 }}>
              4 вересня - останній день тестування четвертої групи. Дякуємо.
            </Alert>
          </Container>
        )
      } */}

    </>
  )
}
