import React, { useEffect} from 'react'
import { Typography, Box, Stack, Link, MenuItem, Paper, ListItemText, Avatar } from '@mui/material'
import { BASE_URL } from '../../config'
import { capitalizeFirstLetter } from '../../clientHelpers/capitalizeFirstLetter '
import { useSelector, useDispatch } from 'react-redux'
import { fetchTopTags } from '../../redux/slices/songs'
import { LIMIT_8 } from '../../config'

export default function TagInfo({ tag }) {
  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch(fetchTopTags({ limit: LIMIT_8 }))
  }, [dispatch])
  const topTags = useSelector(state => state.songs.topTags)

  return (
    <>
      <Stack sx={{ alignItems: 'center' }}>
          {/* <Typography variant='h3' sx={{ textAlign: 'center' }}>{ capitalizeFirstLetter(tag) }</Typography> */}
          <Box
            component="img"
            src={`${BASE_URL}/uploads/tags/${tag}.jpeg`}
            alt={tag}
            sx={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
          />
      </Stack>

      <Typography variant='h4' sx={{ textAlign: 'center', mt: 4 }}>Популярные жанры и стили</Typography>
      <Paper sx={{ border: '1px solid #1976D9', mt: 2, pt: 0.5, pb: 0.5 }}>
        {
          topTags.items.map(item => {
            return (
              <MenuItem component={Link} href={`/style/${item[0]}`}>
                <ListItemText sx={{ color: '#1976D9'}}>
                  {capitalizeFirstLetter(item[0])}
                </ListItemText>
              </MenuItem>
            )
          })
        }
      </Paper>
    </>
  )
}
