import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRegister, selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import { Paper, Typography, Box, TextField, Button, Grid, Link, Alert  } from '@mui/material'
import Cookies from 'js-cookie';

export default function Signup() {
  const [name, setName] = useState('')
  const [login, setLogin] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isRegisterCookie, setIsRegisterCookie] = useState(false);
  const isAuth = useSelector(selectIsAuth)
  const dispatch = useDispatch()

  useEffect(() => {
    getFromCookie()
  }, [])

  const getFromCookie = () => {
    const value = Cookies.get('isRegister')
    setIsRegisterCookie(value || false)
  }

  async function onSubmit (e) {
    e.preventDefault()
    const values = {
      name:  name.trim(),
      login: login.trim().toLowerCase(),
      email: email.trim().toLowerCase(),
      password: password.trim()
    }

    try {
      const data = await dispatch(fetchRegister(values))

      if (!data.payload.success) {
        return setError(data.payload.message);
      }

      // если авторизация прошла успешно, то сохраняем в локалсторадж наш токен, по которому
      // мы потом будем проверять авторизацию
      if ('token' in data.payload) {
        window.localStorage.setItem('token', data.payload.token)
      }

      Cookies.set('isRegister', true, { expires: 3650 })

    } catch (error) {
      alert(error.message || 'Произошла ошибка при регистрации')
    }
  }

  if (isAuth) {
    if (!isRegisterCookie) {
      Cookies.set('isRegister', true, { expires: 3650 }); // 3650 дней (10 лет)
    }
    return <Navigate to='/' />
  }

  return (
    <Paper sx={{ padding: 2, display: 'flex', justifyContent: 'center', height: '67vh'}}>
      <Box sx={{ maxWidth: '500px' }}>
        <Typography component="h1" variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
          Регистрация временно недоступна
          {/* Регистрация */}
        </Typography>
        {
          error && (<Alert severity="error" sx={{ mb: 1 }}>{ error }</Alert>)
        }
        {
          isRegisterCookie ? (
            <>
            <Alert severity="success" sx={{ mb: 1, fontSize: '18px' }}>Отличная новость! <br />Вы уже зарегистрированы на нашем сайте!</Alert>
            <Grid container>
              <Grid item>
                <Box sx={{ width: '500px', textAlign: 'center', mt: 4 }}>
                  <Link href="/login" variant="body2" sx={{ fontSize: '18px' }}>
                    {"Войти по логину и паролю"}
                  </Link>
                </Box>
              </Grid>
            </Grid>
            </>
          ) : (
            <>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Имя для публикации"
                name="name"
                autoComplete="name"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Логин"
                name="login"
                autoComplete="login"
                onChange={(e) => setLogin(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={onSubmit}
              >
                Зарегистрироваться
              </Button> */}
              <Grid container>
                <Grid item>
                  <Link href="/login" variant="body2">
                    {"У вас уже есть аккаунт? Авторизуйтесь"}
                  </Link>
                </Grid>
              </Grid>
            </>
          )
        } 
      </Box>
    </Paper>
  )
}
