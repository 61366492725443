import React, { useState, useEffect } from 'react'
import { Paper } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { selectIsAuth } from '../../redux/slices/auth'
import Settings from './Settings'
import SongsList from './SongsList'
import SelectedSong from './SelectedSong'
import { fetchInProgressSongs, fetchSongsByUserId } from '../../redux/slices/songs'
import localAxios from '../../axios'
import axios from 'axios'
import { SUNO_API_URL, BASE_URL, GENERAL, LIMIT_10 } from '../../config'
import Player from '../../components/Player';
import Cookies from 'js-cookie';

export default function CreateSong() {
    const isAuth = useSelector(selectIsAuth)

    if (!window.localStorage.getItem('token') && !isAuth) {
        return <Navigate to='/' />
    }

    useEffect(() => {
        const value = Cookies.get('isRegister')
        if (!value) { Cookies.set('isRegister', true, { expires: 3650 }) }
    }, [])

    const dispatch = useDispatch()
    const me = useSelector(state => state.auth.data)
    const userId = me?._id
    const [filter, setFilter] = useState(GENERAL)
    const [flag, setFlag] = useState(true)
    const [page, setPage] = useState(1)

    const songs = useSelector(state => state.songs.songs)
    const inProgressSongs = useSelector(state => state.songs.inProgressSongs.items)

    // получаем все песни по userId
    useEffect(()=> {
        if (userId) {
            updateSongsInfo()
            dispatch(fetchSongsByUserId({ userId, status: filter, page, limit: LIMIT_10 }))
        }
    }, [userId, filter, dispatch, page])

    // вытаскиваем песни из базы данных, где еще нет полных данных, включающих audio_url
    useEffect(()=> {
        if (userId && flag) {
            dispatch(fetchInProgressSongs(userId))
        }
    }, [userId, flag, dispatch])


    function updateSongsInfo () {
        console.log('UPDATE SONGS INFO')
        const inProgressSongsIds = inProgressSongs.map(item => item.song.id)

        if (inProgressSongsIds.length) {
            const fetchData = async () => {
                const idsString = inProgressSongsIds.join(',');

                try {
                    // достаем полные данные по уже сгенерированным песням
                    const { data } = await axios.get(`${SUNO_API_URL}/api/get?ids=${idsString}`)

                    // соединяем эти данные с данными, которые были получены из базы данных
                    const mergedArray = mergeSongsVersions(data)

                    if (mergedArray.length) {
                        // обновляем информацию в базе данных по этим записям
                        await localAxios.patch(`${BASE_URL}/song/merge-songs`, { mergedArray }).then(()=>{
                            dispatch(fetchInProgressSongs(userId))
                            dispatch(fetchSongsByUserId({ userId, status: filter, page: 1, limit: LIMIT_10 }))
                        })
                    }
                } catch (error) {
                    console.warn(error);
                }
            }
            fetchData();
        }
    }

    function mergeSongsVersions (data) {
        const mergedArray = inProgressSongs.map(currentItem => {
            const updatedItem = data.find(item => item.id === currentItem.song.id);
            if (updatedItem) {
                return {
                ...currentItem,
                song: {
                    ...currentItem.song,
                    ...updatedItem,
                    title: currentItem.song.title
                }
                }
            } else {
                return currentItem;
            }
        });
        return mergedArray
    }

    const Item = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

    return (
        <>
            <Player />
            <Grid container spacing={2}>
                <Grid xs={12} md={4}>
                    <Item>
                        <Settings
                            me={me}
                            inProgressSongs={inProgressSongs}
                            flag={flag}
                            setFlag={setFlag}
                            updateSongsInfo={updateSongsInfo}
                        />
                    </Item>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>
                        <SongsList
                            songs={songs}
                            filter={filter}
                            setFilter={setFilter}
                            page={page}
                            setPage={setPage}
                        />
                    </Item>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>
                        <SelectedSong />
                    </Item>
                </Grid>
            </Grid>
        </>
    )
}
