import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'
import localAxios from '../../axios'
import { Paper, Typography, Box, Link, Button, TextField, Alert, Stack } from '@mui/material'

export default function ResetPassword() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const isAuth = useSelector(selectIsAuth)

  if (isAuth) {
    return <Navigate to='/cabinet/studio' />
  }

  async function onSubmit(e) {
    e.preventDefault();

    const values = {
      email: email.trim().toLowerCase()
    };

    try {
      const data = localAxios.post('/auth/forgot-password', { email: email.trim().toLowerCase() })

      if (data.success) {
        return setSuccess('Мы отправили на Ваш почтовый ящик ссылку для восстановления парол')
      }

      if (!data.success) {
        return setError(data.message);
      }


    } catch (error) {
      alert(error.message || 'Произошла ошибка при попытке восстановить пароль');
    }
  }



  return (
    <Paper sx={{ padding: 2, display: 'flex', justifyContent: 'center', height: '67vh'}}>
      <Box sx={{ maxWidth: '500px' }}>
        <Typography component="h1" variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
          Восстановление пароля
        </Typography>
        {
          error && (<Alert severity="error" sx={{ mb: 1 }}>{ error }</Alert>)
        }
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Введите Ваш Email"
          name="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 3 }}
          onClick={onSubmit}
        >
          Восстановить пароль
        </Button>
        <Stack direction='row' justifyContent='space-between'>
            <Link href="/login" variant="body2">
              Вход
            </Link>
            <Link href="/signup" variant="body2">
              Регистрация
            </Link>
        </Stack>
      </Box>
    </Paper>
  )
}
